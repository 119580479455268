"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTDwgServer = void 0;
const ltlinetypepattern_1 = require("./ltlinetypepattern");
//import { LTBlockReference, LTPolyline } from "./ltentity";
const ltutils_1 = require("./ltutils");
const ltvectorlayer_1 = require("../data/ltvectorlayer");
//import { LTField } from "../data/ltfields";
const ltfeature_1 = require("../data/ltfeature");
const ltpoint_1 = require("./ltpoint");
const ltentity_1 = require("./ltentity");
const rdimstyle_1 = require("./rdimstyle");
const lttextstyles_1 = require("../data/lttextstyles");
/**
 *  LTDwgServer 存储cad文件的信息,如线型 图层颜色 块信息
 */
class LTDwgServer {
    ;
    constructor(map) {
        this._currentLinetype = ltentity_1.dwg_bylayer;
        this._dwgservice = null;
        this._isblockdirty = false;
        this._islinetypedirty = false;
        this._ishatchsdirty = false;
        this._istextStyledirty = false;
        this._hatchsjson = [];
        this._map = map;
        //线型
        this._linetypes = new Map();
        //块
        this._blocks = new Map();
        //图纸比例尺
        this._ltscale = 1;
        this.first = true;
        this._hatchs = new Map();
        this._dimStyle = new Map();
        this._dimblocks = new Map();
        this._textStyles = new Map();
    }
    get dwgService() {
        return this._dwgservice || {};
    }
    get dwgScale() {
        return this._ltscale;
    }
    set dwgScale(scale) {
        if (scale == undefined) {
            scale = 1000;
        }
        this._ltscale = scale;
    }
    set dwgService(dwg) {
        this._dwgservice = dwg;
    }
    setBlockDirty(isdirty) {
        this._isblockdirty = isdirty;
    }
    getBlockDirty() {
        return this._isblockdirty;
    }
    setLinetypeDirty(isdirty) {
        this._islinetypedirty = isdirty;
    }
    getLinetypeDirty() {
        return this._islinetypedirty;
    }
    getHatchsDirty() {
        return this._ishatchsdirty;
    }
    getTextStyleDirty() {
        return this._istextStyledirty;
    }
    isSaveOK() {
        this._ishatchsdirty = false;
        this._islinetypedirty = false;
        this._isblockdirty = false;
        this._istextStyledirty = false;
    }
    setmap(map) {
        if (this._map == null) {
            this._map = map;
        }
    }
    getMap() {
        return this._map;
    }
    addDesign(json) {
        let blocks = json.blocks;
        let block = json.block;
        this.setBlocks(blocks);
        this._setblock(block);
        let hatch = json.hatchinfo;
        this._sethatchs(hatch, true);
        let layer = json.layerinfo;
        this._setlayer(layer, false, null);
        this._ishatchsdirty = true;
        this._islinetypedirty = true;
        this._isblockdirty = true;
    }
    setFeatures(features, layer = null) {
        if (this._map === null) {
            return;
        }
        let layer_map = new Map();
        let layers = this._map.getLayers();
        for (let sub_layer of layers) {
            layer_map.set(sub_layer.name, sub_layer);
        }
        for (let i = 0; i < features.length; i++) {
            let feature = features[i];
            let status = feature.status;
            if (status == ltfeature_1.CommitFlag.Delete || status === "delete") {
                continue;
            }
            let id = feature.id;
            let layer_name = feature.entitylayer;
            let temp_layer = layer_map.get(layer_name);
            if (temp_layer === undefined) {
                temp_layer = layer_map.get("0");
            }
            var fea = new ltfeature_1.LTFeature({
                id,
                geometry: null,
                properties: feature,
                status: ltfeature_1.CommitFlag.Nothing,
                layer: temp_layer,
                xdata: feature.xdata
            });
            //无效不更新
            if (fea.valid() == false) {
                continue;
            }
            fea.commit(ltfeature_1.CommitFlag.Add, false);
            //无状态
            fea.state = ltfeature_1.CommitFlag.Nothing;
            //原来状态 保存的时候判断
            //   fea.old_state = status;
        }
    }
    getLayerLineType(layername) {
        if (this._map === null) {
            return null;
        }
        var it = this._map.getLayerByName(layername);
        if (it == null) {
            return null;
        }
        return this.getLineType(it._linetype);
    }
    getLinetypesName() {
        let list = [ltentity_1.dwg_bylayer, "Continuous"];
        list = list.concat(Array.from(this._linetypes.keys()));
        return list;
    }
    /**
     * \brief 获取线型 (LTLinetypePattern)
     * \param name 线型名字
     */
    getLineType(name) {
        var it = this._linetypes.get(name) || null;
        return it;
    }
    setLineType(linetype, unitConvert) {
        let name = linetype.name;
        if (this.getLineType(name) !== null) {
            return;
        }
        let description = linetype.comments;
        let patternString = linetype.pattern;
        let dwgpattern = linetype.patterndwg;
        if (patternString.length == 0) {
            return;
        }
        let pattern = new ltlinetypepattern_1.LTLinetypePattern(true, name, description);
        pattern.setUnitConvert(unitConvert);
        pattern.setSacle(this._ltscale);
        const decodedBuffer = Buffer.from(patternString, 'base64'); // 解码为Buffer  
        const decodedString = decodedBuffer.toString('utf8'); // 将Buffer转换为字符串  
        pattern.setPatternString(decodedString);
        if (dwgpattern != undefined && dwgpattern != null) {
            const decodedBuffer1 = Buffer.from(dwgpattern, 'base64'); // 解码为Buffer  
            const decodedString1 = decodedBuffer1.toString('utf8'); // 将Buffer转换为字符串  
            pattern.setDwgPattern(decodedString1);
        }
        this._linetypes.set(name, pattern);
    }
    setLineTypesByOnline(linetypes) {
        this._islinetypedirty = true;
        for (let i = 0; i < linetypes.length; ++i) {
            let linetype = linetypes[i];
            this.setLineType(linetype, true);
        }
    }
    getCurrentLineType() {
        return this._currentLinetype;
    }
    setCurrentLineType(name) {
        this._currentLinetype = name;
    }
    /**
     * \brief 设置线型，一开始初始化的时候调用
     * \param linetypes 线型信息 json格式
     */
    setLinetypes(linetypes) {
        //解析线型
        let json_value = JSON.parse(linetypes);
        if (json_value == null) {
            return;
        }
        for (let i = 0; i < json_value.length; ++i) {
            let json = json_value[i];
            this.setLineType(json, false);
        }
    }
    /**
     * \brief 获取图层颜色
     * \param name 图层名
     */
    getLayerColor(name) {
        if (this._map === null) {
            return "0xffffff";
        }
        var it = this._map.getLayerByName(name);
        if (it == null) {
            return "0x00ff00";
        }
        return it.color;
    }
    /**
     * \brief 设置图层信息
     * \param colors json格式的图层信息
     */
    loadLayerFinal() {
        if (this._map === null) {
            return;
        }
        this._load_layer--;
        if (this._load_layer == 0) {
            this._map.loadFinal();
        }
    }
    _setlayer(json_value, init, process_callback) {
        if (json_value === undefined || json_value === null) {
            return;
        }
        this._load_layer = 0;
        for (var index = 0; index < json_value.length; index++) {
            let js = json_value[index];
            let name = js.layername;
            let jsinfo = js.info;
            if (name == "") {
                continue;
            }
            if (this._map.getLayerByName(name) !== null) {
                continue;
            }
            var layer = new ltvectorlayer_1.LTVectorlayer(name);
            layer.setColorInfo(jsinfo.color);
            layer.forzen = jsinfo.isFrozen;
            layer.locked = jsinfo.isLocked;
            layer.off = jsinfo.isOff;
            layer._linetype = jsinfo.linetype;
            layer._linewidth = 0; //jsinfo.linewidth;
            //this._layerLinetypes.set(name, jsinfo.linetype);
            this._load_layer++;
            this._map.addLayer(layer);
        }
        if (init) {
            this.dwgService.initData(this._map, process_callback);
        }
    }
    setLayerInfo(info, process_callback) {
        if (this._map === null) {
            return;
        }
        let json_value = JSON.parse(info);
        if (json_value == null) {
            return;
        }
        if (json_value.length == 0) {
            this._map.loadFinal();
        }
        this._setlayer(json_value, true, process_callback);
    }
    /**
     * \brief 设置图纸的比例尺
     */
    getLtScale() {
        return this._ltscale;
    }
    /**
     * \brief 获取图纸比例尺
     */
    setLtScale(scale) {
        this._ltscale = scale;
    }
    /**
     * \brief 获取块 (LTBlockReference)
     * \param name 块名
     */
    getBlock(name) {
        let it = this._blocks.get(name);
        if (it == null) {
            return null;
        }
        return it;
    }
    hasBlock(name) {
        return this._blocks.has(name);
    }
    setBlock(name, block) {
        this._isblockdirty = true;
        block._isupdate = true;
        this._blocks.set(name, block);
    }
    setCurrentInfo(info) {
        if (info === undefined || info === null) {
            return;
        }
        if (info.length == 0) {
            return;
        }
        let json_value = JSON.parse(info);
        if (json_value == null) {
            return;
        }
        this._currentLinetype = json_value.currentLinetype;
        let layer = this._map.getLayerByName(json_value.currentLayername);
        if (layer !== null) {
            this._map.setActiveLayer(layer);
        }
    }
    getLineTypesSaveInfo() {
        let linetypesinfo = [];
        let json = [];
        this._linetypes.forEach((linetype, name) => {
            let blockinfo = {};
            blockinfo["comments"] = linetype.getDescription();
            blockinfo["name"] = name;
            const buffer = Buffer.from(linetype.getPatternString(), 'utf8');
            blockinfo["pattern"] = buffer.toString('base64');
            if (linetype.getDwgPattern() != undefined && linetype.getDwgPattern() != null) {
                const buffer1 = Buffer.from(linetype.getDwgPattern(), 'utf8');
                blockinfo["patterndwg"] = buffer1.toString('base64');
            }
            linetypesinfo.push(blockinfo);
        });
        return linetypesinfo;
    }
    getBlockSaveInfo() {
        let blocksinfo = [];
        let json = [];
        this._blocks.forEach((block, name) => {
            let blockinfo = {};
            blockinfo["blockname"] = name;
            blockinfo["geometrycount"] = block._entitys.length;
            let index = 0;
            block._entitys.forEach((entity) => {
                let data = entity.getSaveValue();
                let entjson = { color: entity.getColorInfo(), entitylayer: entity.getLayerName(), entitytype: entity.data.getEntityName(),
                    linetype: entity.getLineType(), width: data.width, info: data.info, cadgeo: data.cadgeo, blockname: "",
                    rotate: entity.data.getRotate(), text: data.text };
                if (data.blockname !== undefined && data.blockname !== null) {
                    entjson.blockname = data.blockname;
                }
                // blockinfo.push(entjson);
                blockinfo[index.toString()] = entjson;
                index++;
            });
            blockinfo["update"] = block._isupdate;
            blocksinfo.push(blockinfo);
        });
        //blockname, blockRef);
        return blocksinfo;
    }
    getTextStyleSaveInfo() {
        let info = [];
        let json = [];
        this._textStyles.forEach((linetype, name) => {
            info.push(linetype.saveInfo());
        });
        return info;
    }
    _setblock(js) {
        //块名
        let blockname = js["blockname"];
        //块的几何组成
        let count = js["geometrycount"];
        let blockRef = new ltentity_1.LTBlockReference();
        for (var c = 0; c < count; ++c) {
            let js1 = js[c];
            if (js1 == null) {
                continue;
            }
            //几何解析
            var pl = ltutils_1.LTUtils.json2Entity(js1);
            if (pl !== null) {
                blockRef.addEntity(pl);
            }
        }
        if (js["origin"] == false) {
            let x = js["originx"];
            let y = js["originy"];
            blockRef.movePoint(new ltpoint_1.LTPoint(-x, -y));
        }
        blockRef._isupdate = js["update"];
        //更新块的空间索引;
        blockRef.updateSpatialIndex();
        this._blocks.set(blockname, blockRef);
    }
    /**
     * \brief 获取块信息
     * \param name 块名
     */
    setBlocks(blockjson) {
        let json_value;
        if (typeof blockjson === "string") {
            json_value = JSON.parse(blockjson);
        }
        else {
            json_value = blockjson;
        }
        if (json_value == null) {
            return;
        }
        for (var i = 0; i < json_value.length; ++i) {
            var js = json_value[i];
            this._setblock(js);
        }
    }
    _sethatchs(json_value, isadd) {
        if (json_value == null) {
            return;
        }
        for (var i = 0; i < json_value.length; ++i) {
            var js = json_value[i];
            //块名
            let hatchname = js["name"];
            if (this.getHatch(hatchname) !== null) {
                continue;
            }
            //块的几何组成
            let hatch = js["hatch"];
            let pattern = new ltpoint_1.RPattern();
            pattern.name = hatchname;
            pattern.setPatternString(hatch);
            this._hatchs.set(hatchname, pattern);
            if (isadd) {
                js["updata"] = true;
                this._ishatchsdirty = true;
                this._hatchsjson.push(js);
            }
        }
    }
    setHatchs(hatchsjson) {
        this._hatchsjson = JSON.parse(hatchsjson);
        if (this._hatchsjson == null) {
            this._hatchsjson = [];
            return;
        }
        this._sethatchs(this._hatchsjson, false);
    }
    getHatch(name) {
        let it = this._hatchs.get(name);
        if (it == null) {
            return null;
        }
        return it;
    }
    setDimStyle(str) {
        if (str == null || str == undefined) {
            return;
        }
        let json_value = JSON.parse(str);
        if (json_value == null) {
            return;
        }
        for (var i = 0; i < json_value.length; ++i) {
            var js = json_value[i];
            let pattern = new rdimstyle_1.RDimStyle();
            pattern.name = js.name;
            pattern.setValue(js);
            this._dimStyle.set(js.name, pattern);
        }
    }
    getDimStyle(name) {
        let it = this._dimStyle.get(name);
        if (it == null) {
            return null;
        }
        return it;
    }
    setTextStyle(str) {
        if (str == null || str == undefined) {
            return;
        }
        let json_value = JSON.parse(str);
        if (json_value == null) {
            return;
        }
        for (var i = 0; i < json_value.length; ++i) {
            var js = json_value[i];
            let pattern = new lttextstyles_1.LTTextStyle();
            pattern.setInfo(js);
            this._textStyles.set(js.name, pattern);
        }
    }
    getTextStyle(name) {
        let it = this._textStyles.get(name);
        if (it == null) {
            return null;
        }
        return it;
    }
    setDimTextXScale(name, xScale) {
        let dim = this.getDimStyle(name);
        let isupdate = false;
        if (dim !== null) {
            let textname = dim === null || dim === void 0 ? void 0 : dim.getDimString("dimtxsty");
            let textstyle = this.getTextStyle(textname);
            if (textstyle !== null) {
                textstyle.xScale = xScale;
                textstyle._update = true;
                isupdate = true;
            }
        }
        if (isupdate) {
            this._istextStyledirty = true;
            //重刷
            // this._map.regen();
        }
    }
    //获取标注的块列表
    getDimBlock() {
        if (this._dimblocks.size > 0) {
            return this._dimblocks;
        }
        this._dimblocks.set("实心闭合", "_ClosedSoild");
        this._dimblocks.set("空心闭合", "_ClosedBlank");
        this._dimblocks.set("闭合", "_Closed");
        this._dimblocks.set("点", "_Dot");
        this._dimblocks.set("建筑标记", "_ArchTick");
        this._dimblocks.set("倾斜", "_Oblique");
        this._dimblocks.set("打开", "_Open");
        this._dimblocks.set("指示原点", "_Origin");
        this._dimblocks.set("指示原点2", "_Origin2");
        this._dimblocks.set("直角", "_Open90");
        this._dimblocks.set("30度角", "_Open30");
        this._dimblocks.set("小点", "_DotSmall");
        this._dimblocks.set("空心点", "_DotBlank");
        this._dimblocks.set("空心小点", "_Small");
        this._dimblocks.set("方框", "_BoxBlank");
        this._dimblocks.set("实心方框", "_BoxFilled");
        this._dimblocks.set("基准三角形", "_DatumBlank");
        this._dimblocks.set("实心基准三角形", "_DatumFilled");
        this._dimblocks.set("积分", "_Integral");
        return this._dimblocks;
    }
}
exports.LTDwgServer = LTDwgServer;
