"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RPattern = exports.RPatternLine = exports.LTPoints = exports.RBox = exports.LTPoint = exports.Flag = void 0;
const ltutils_1 = require("./ltutils");
const segment_1 = require("./segment");
const ltmath_1 = require("./ltmath");
/**
 * LTPoint 二维点
 */
const tol = 1.0e-9;
const PointTolerance = 1e-9;
exports.Flag = {
    NoFlags: 0x000,
    Secondary: 0x001,
    Tertiary: 0x002,
    Center: 0x004 /**< Reference point is a center point */,
    Ignore: 0x008 /**< Ignore reference point for drag and drop */,
    Start: 0x010 /**< Reference point is a start point */,
    End: 0x020 /**< Reference point is a start point */,
    Arrow: 0x040 /**< Reference point is a dimension arrow */,
    Selected: 0x080 /**< Reference point is selected */
};
class LTPoint {
    constructor(temp_x, temp_y, flag = 0, valid = true) {
        this._valid = false;
        this._flag = 0;
        this.x = temp_x;
        this.y = temp_y;
        this._flag = flag;
        this._valid = valid;
    }
    set valid(flag) {
        this._valid = flag;
    }
    get valid() {
        return this._valid;
    }
    set flag(flag) {
        this._flag = flag;
    }
    get flag() {
        return this._flag;
    }
    toJson() {
        return { x: this.x, y: this.y };
    }
    toJsonString() {
        return JSON.stringify(this.toJson());
    }
    isSane() {
        return this.isValid() && ltmath_1.LTMath.isSane(this.x) && ltmath_1.LTMath.isSane(this.y);
    }
    clone() {
        let ret = new LTPoint(this.x, this.y, this._flag);
        return ret;
    }
    getx() {
        return this.x;
    }
    gety() {
        return this.y;
    }
    isValid() {
        return this._valid;
    }
    setMagnitude(v) {
    }
    setPolar(radius, angle) {
        this.x = radius * Math.cos(angle);
        this.y = radius * Math.sin(angle);
    }
    static createPolar(radius, angle) {
        var ret = new LTPoint(0, 0);
        ret.setPolar(radius, angle);
        return ret;
    }
    static nullVector() {
        var ret = new LTPoint(0, 0, 0, false);
        return ret;
    }
    getAngle() {
        var ret = 0;
        var m = this.getMagnitude();
        if (m > 1e-6) {
            var dp = LTPoint.getDotProduct(this, new LTPoint(1.0, 0));
            if (dp / m >= 1.0) {
                ret = 0.0;
            }
            else if (dp / m < -1.0) {
                ret = Math.PI;
            }
            else {
                ret = Math.acos(dp / m);
            }
            if (this.y < 0) {
                ret = 2 * Math.PI - ret;
            }
        }
        return ret;
    }
    getAngleTo(v) {
        return new LTPoint(v.x - this.x, v.y - this.y).getAngle();
    }
    getDistanceTo(v) {
        return new LTPoint(v.x - this.x, v.y - this.y).getMagnitude();
    }
    static getDotProduct(v1, v2) {
        return v1.x * v2.x + v1.y * v2.y;
    }
    getMagnitude() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    rotate(rotation, center = null) {
        if (center != null) {
            var pos = center.plus(this.minus(center).rotate(rotation));
            this.x = pos.x;
            this.y = pos.y;
            return this;
        }
        var r = this.getMagnitude();
        var a = this.getAngle() + rotation;
        this.x = Math.cos(a) * r;
        this.y = Math.sin(a) * r;
        return this;
    }
    scale(scale_x, scale_y, center = null) {
        if (center == null || center == undefined) {
            this.x *= scale_x;
            this.y *= scale_y;
            return this;
        }
        let pos = center.plus(this.minus(center).scale(scale_x, scale_y));
        this.x = pos.x;
        this.y = pos.y;
        return this;
    }
    plus(v) {
        return new LTPoint(this.x + v.x, this.y + v.y);
    }
    minus(v) {
        return new LTPoint(this.x - v.x, this.y - v.y);
    }
    //运算符*
    dot(distance) {
        return new LTPoint(this.x * distance, this.y * distance);
    }
    dotPoint(pos) {
        return new LTPoint(this.x * pos.x, this.y * pos.y);
    }
    move(offset_x, offset_y) {
        this.x += offset_x;
        this.y += offset_y;
    }
    moveNewPoint(offset_x, offset_y) {
        return new LTPoint(this.x + offset_x, this.y + offset_y);
    }
    isInside(b) {
        var bMin = b.getMinimum();
        var bMax = b.getMaximum();
        return (this.x >= bMin.x &&
            this.x <= bMax.x &&
            this.y >= bMin.y &&
            this.y <= bMax.y);
    }
    static getAverage(v1, v2) {
        return new LTPoint((v1.x + v2.x) * 0.5, (v1.y + v2.y) * 0.5);
    }
    static scaleList(list, factors, center) {
        for (let i = 0; i < list.length; i++) {
            list[i].scale(factors.x, factors.y, center);
        }
    }
    static rotateList(list, rotation) {
        for (let i = 0; i < list.length; i++) {
            list[i].rotate(rotation);
        }
    }
    static rotateListByPoint(list, rotation, pos) {
        for (let i = 0; i < list.length; i++) {
            list[i].rotate(rotation, pos);
        }
    }
    static moveList(list, offset) {
        for (let i = 0; i < list.length; i++) {
            list[i].move(offset.x, offset.y);
        }
    }
    static getMinimun(v1, v2) {
        return new LTPoint(Math.min(v1.x, v2.x), Math.min(v1.y, v2.y));
    }
    static getMaximun(v1, v2) {
        return new LTPoint(Math.max(v1.x, v2.x), Math.max(v1.y, v2.y));
    }
    getClosest(list) {
        var index = this.getClosestIndex(list);
        if (index == -1) {
            return null;
        }
        return list[index];
    }
    getNormalized() {
        var l = this.getMagnitude();
        if (l < 1e-6) {
            return new LTPoint(0, 0);
        }
        return new LTPoint(this.x / l, this.y / l);
    }
    getClosestIndex(list) {
        var minDist = -1;
        var index = -1;
        for (var i = 0; i < list.length; ++i) {
            if (list[i] != null) {
                var dist = this.getDistanceTo(list[i]);
                if (minDist < 0 || dist < minDist) {
                    minDist = dist;
                    index = i;
                }
            }
        }
        return index;
    }
    getMagnitude2D() {
        if (!this._valid) {
            return NaN;
        }
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    getDistanceTo2D(v) {
        if (!this._valid || !v._valid) {
            return NaN;
        }
        else {
            return this.minus(v).getMagnitude2D();
        }
    }
    calculatePoint(angle, distance) {
        const targetX = this.x + distance * Math.cos(angle);
        const targetY = this.y + distance * Math.sin(angle);
        return new LTPoint(targetX, targetY);
    }
    mirror(axis) {
        var phi1 = axis.getStartPoint().getAngleTo(this);
        var phi2 = axis.getStartPoint().getAngleTo(axis.getEndPoint()) - phi1;
        var r1 = axis.getStartPoint().getDistanceTo(this);
        var r2 = axis.getEndPoint().getDistanceTo(this);
        if (r1 < 1e-6 || r2 < 1e-6) {
        }
        else {
            this.setPolar(r1, phi1 + 2 * phi2);
            this.x += axis.getStartPoint().x;
            this.y += axis.getStartPoint().y;
        }
        return this;
    }
    equalsFuzzy(v, tolerance = tol) {
        return Math.abs(this.x - v.x) < tolerance && Math.abs(this.y - v.y) < tolerance;
    }
    equals(v) {
        return this.x === v.x && this.y === v.y;
    }
    getLerp(v, t) {
        return new LTPoint(this.x + (v.x - this.x) * t, this.y + (v.y - this.y) * t);
    }
    static getSortedByDistance(list, v) {
        list.sort((a, b) => {
            return v.getDistanceTo(a) - v.getDistanceTo(b);
        });
        return list;
    }
    static getSortedByAngle(points, center, angle) {
        points.sort((a, b) => {
            let a1 = center.getAngleTo(a);
            let a2 = center.getAngleTo(b);
            return ltmath_1.LTMath.getAngleDifference(angle, a1) - ltmath_1.LTMath.getAngleDifference(angle, a2);
        });
        let target = [];
        points.forEach(element => {
            target.push(element.clone());
        });
        return target;
    }
}
exports.LTPoint = LTPoint;
class RBox {
    constructor(x1, y1, x2, y2) {
        this._valid = false;
        this.c1 = new LTPoint(x1, y1);
        this.c2 = new LTPoint(x2, y2);
        this._valid = true;
    }
    static formVectors(v1, v2) {
        let c = LTPoint.getMinimun(v1, v2);
        let b = LTPoint.getMaximun(v1, v2);
        return new RBox(c.x, c.y, b.x, b.y);
    }
    static fromWidthHeight(x, y, width, height) {
        return new RBox(x, y, x + width, y + height);
    }
    static nullBox() {
        let result = new RBox(0, 0, 0, 0);
        result._valid = false;
        return result;
    }
    clone() {
        return RBox.formVectors(this.c1.clone(), this.c2.clone());
    }
    setVector(v1, v2) {
        let c = LTPoint.getMinimun(v1, v2);
        let b = LTPoint.getMaximun(v1, v2);
        this.c1 = c;
        this.c2 = b;
        this._valid = true;
    }
    static fromCenter(center, distance) {
        return new RBox(center.x - distance, center.y - distance, center.x + distance, center.y + distance);
    }
    intersects(other) {
        var maximum = this.getMaximum();
        var minimum = this.getMinimum();
        var otherMaximum = other.getMaximum();
        var otherMinimum = other.getMinimum();
        if (minimum.x > otherMaximum.x ||
            minimum.y > otherMaximum.y) {
            return false;
        }
        if (maximum.x < otherMinimum.x ||
            maximum.y < otherMinimum.y) {
            return false;
        }
        return true;
    }
    getCenter() {
        return new LTPoint((this.c1.x + this.c2.x) / 2, (this.c1.y + this.c2.y) / 2);
    }
    expand(point) {
        if (!point.isValid()) {
            return;
        }
        if (this._valid == false) {
            this.c1 = point;
            this.c2 = point;
        }
        this._valid = true;
        this.c1 = new LTPoint(Math.min(this.getMinimum().x, point.x), Math.min(this.getMinimum().y, point.y));
        this.c2 = new LTPoint(Math.max(this.getMaximum().x, point.x), Math.max(this.getMaximum().y, point.y));
    }
    getMinimum() {
        return LTPoint.getMinimun(this.c1, this.c2);
    }
    /**
     * \return Maximum point of this box (maximum X,Z and Z).
     */
    getMaximum() {
        return LTPoint.getMaximun(this.c1, this.c2);
    }
    grow(offset) {
        var min = this.getMinimum();
        var max = this.getMaximum();
        this.c1 = min.minus(new LTPoint(offset, offset));
        this.c2 = max.plus(new LTPoint(offset, offset));
        return this;
    }
    move(offset) {
        this.c1.move(offset.x, offset.y);
        this.c2.move(offset.x, offset.y);
    }
    scale(scale_factor, center_pos) {
        this.c1.scale(scale_factor.x, scale_factor.y, center_pos);
        this.c2.scale(scale_factor.x, scale_factor.y, center_pos);
    }
    getWidth() {
        return Math.abs(this.c2.x - this.c1.x);
    }
    getHeight() {
        return Math.abs(this.c2.y - this.c1.y);
    }
    getArea() {
        return this.getWidth() * this.getHeight();
    }
    isOutside(other) {
        var maximum = this.getMaximum();
        var minimum = this.getMinimum();
        var otherMaximum = other.getMaximum();
        var otherMinimum = other.getMinimum();
        return (minimum.x > otherMaximum.x ||
            minimum.y > otherMaximum.y ||
            maximum.x < otherMinimum.x ||
            maximum.y < otherMinimum.y);
    }
    contain(other) {
        if (other instanceof RBox) {
            return other.c1.isInside(this) && other.c2.isInside(this);
        }
        return other.isInside(this);
    }
    intersectsWith(shape, limited) {
        if (limited && !this.intersects(shape.getBoundingBox())) {
            return false;
        }
        var boxEdges = this.getLines2d();
        for (var i = 0; i < boxEdges.length; i++) {
            if (boxEdges[i].intersectsWith(shape, limited)) {
                return true;
            }
        }
        return false;
    }
    isValid() {
        return this._valid;
    }
    growToInclude(other) {
        if (!this.isValid()) {
            this.c1 = other.c1;
            this.c2 = other.c2;
            this._valid = other._valid;
            return;
        }
        var maximum = this.getMaximum();
        var minimum = this.getMinimum();
        var otherMaximum = other.getMaximum();
        var otherMinimum = other.getMinimum();
        this.c1 = LTPoint.getMinimun(minimum, otherMinimum);
        this.c2 = LTPoint.getMaximun(maximum, otherMaximum);
    }
    getCorners2d() {
        var ret = [];
        ret.push(new LTPoint(this.c1.x, this.c1.y));
        ret.push(new LTPoint(this.c2.x, this.c1.y));
        ret.push(new LTPoint(this.c2.x, this.c2.y));
        ret.push(new LTPoint(this.c1.x, this.c2.y));
        return ret;
    }
    getLines2d() {
        var ret = [];
        ret.push(new segment_1.RLine(new LTPoint(this.c1.x, this.c1.y), new LTPoint(this.c2.x, this.c1.y)));
        ret.push(new segment_1.RLine(new LTPoint(this.c2.x, this.c1.y), new LTPoint(this.c2.x, this.c2.y)));
        ret.push(new segment_1.RLine(new LTPoint(this.c2.x, this.c2.y), new LTPoint(this.c1.x, this.c2.y)));
        ret.push(new segment_1.RLine(new LTPoint(this.c1.x, this.c2.y), new LTPoint(this.c1.x, this.c1.y)));
        return ret;
    }
}
exports.RBox = RBox;
/**
 * LTPoints 点集合
 */
class LTPoints {
    constructor(points) {
        if (points) {
            this._points = points;
        }
        else {
            this._points = new Array;
        }
    }
    addPoint(point) {
        this._points.push(point);
    }
    movePoint(index, point) {
        this._points[index].move(point.x, point.y);
    }
    replace(i, point) {
        this._points[i] = point;
    }
    getPoint(i) {
        return this._points[i];
    }
    removelast() {
        this._points.pop();
    }
    length() {
        return this._points.length;
    }
    clear() {
        this._points = [];
    }
    //获取当前点的总长度
    getLength() {
        // if (typeof this._dis == "undefined") {
        let startPoint = null;
        this._dis = 0;
        for (var i = 0; i < this.length(); i++) {
            if (i == 0) {
                startPoint = this._points[0];
                continue;
            }
            if (startPoint === null) {
                continue;
            }
            let endPoint = this._points[i];
            this._dis += Math.sqrt((endPoint.x - startPoint.x) * (endPoint.x - startPoint.x) +
                (endPoint.y - startPoint.y) * (endPoint.y - startPoint.y));
            startPoint = endPoint;
        }
        //  }
        return this._dis;
    }
    /**
     * \brief 获取指定线段的角度
     * \param index 第几段
     */
    getAngleAt(index) {
        // if (typeof this._angleAt == "undefined" ) {
        this._angleAt = [];
        for (var i = 0, j = this.length() - 1; i < j; ++i) {
            let angle = ltutils_1.LTUtils.angle(this._points[i], this._points[i + 1]);
            this._angleAt.push(angle);
        }
        //}
        return this._angleAt[index];
    }
    /**
     * \brief 获取点集组合成为线段的长度
     */
    getLengthAt() {
        // if (typeof this._lengthAt == "undefined") {
        let startPoint = {};
        let dis = 0;
        this._lengthAt = [];
        for (let i = 0; i < this._points.length; i++) {
            if (i == 0) {
                startPoint = this._points[0];
                this._lengthAt.push(0);
                continue;
            }
            let endPoint = this._points[i];
            dis += Math.sqrt((endPoint.getx() - startPoint.getx()) *
                (endPoint.getx() - startPoint.getx()) +
                (endPoint.gety() - startPoint.gety()) *
                    (endPoint.gety() - startPoint.gety()));
            this._lengthAt.push(dis);
            startPoint = endPoint;
        }
        //}
        return this._lengthAt;
    }
    /**
     * \brief 获取第i个点到一段距离的点
     * \param index 第几段
     * \param distance 距离
     */
    getPointsWithDistanceToEnd(i, distance) {
        if (this.length() == i + 1) {
            return null;
        }
        let endPoint = this.getPoint(i + 1);
        let startPoint = this.getPoint(i);
        let normalStart = ltutils_1.LTUtils.normalizedPoint(new LTPoint(endPoint.getx() - startPoint.getx(), endPoint.gety() - startPoint.gety()));
        let p = new LTPoint(startPoint.getx() + normalStart.getx() * distance, startPoint.gety() + normalStart.gety() * distance);
        return p;
    }
    move(i, offset) {
        this._points[i].move(offset.x, offset.y);
    }
    getRBox() {
        let box = RBox.nullBox();
        let minx = 0, miny = 0, maxx = 0, maxy = 0;
        for (let i = 0, count = this.length(); i < count; ++i) {
            let x = this._points[i].x;
            let y = this._points[i].y;
            if (i == 0) {
                minx = maxx = x;
                miny = maxy = y;
                continue;
            }
            if (minx >= x) {
                minx = x;
            }
            if (maxx < x) {
                maxx = x;
            }
            if (miny >= y) {
                miny = y;
            }
            if (maxy < y) {
                maxy = y;
            }
        }
        box = new RBox(minx, miny, maxx, maxy);
        return box;
    }
    static toJson(pos) {
        let geo = [];
        for (var i = 0; i < pos.length; i++) {
            geo.push({ "x": pos[i].x, "y": pos[i].y });
        }
        return geo;
    }
}
exports.LTPoints = LTPoints;
class RPatternLine {
    constructor() {
        this.offset = new LTPoint(0, 0);
        this.basePoint = null;
        this.angle = 0;
        this.dashes = [];
        // this.offset = ne;
        // this.basePoint = end;
        // this.dashes = end;
        // this.offset = end;
    }
    clone() {
        var _a;
        let pl = new RPatternLine();
        pl.angle = this.angle;
        pl.offset = this.offset.clone();
        if (this.basePoint !== null) {
            pl.basePoint = (_a = this.basePoint) === null || _a === void 0 ? void 0 : _a.clone();
        }
        for (let i = 0; i < this.dashes.length; i++) {
            pl.dashes.push(this.dashes[i]);
        }
        return pl;
    }
    rotate(a) {
        var _a;
        (_a = this.basePoint) === null || _a === void 0 ? void 0 : _a.rotate(a);
        this.angle = ltmath_1.LTMath.getNormalizedAngle(this.angle + a);
    }
    scale(f) {
        var _a;
        this.offset = this.offset.dot(f);
        if (this.basePoint !== null) {
            this.basePoint = (_a = this.basePoint) === null || _a === void 0 ? void 0 : _a.dot(f);
        }
        for (let i = 0; i < this.dashes.length; i++) {
            this.dashes[i] = this.dashes[i] * f;
        }
    }
    getLength() {
        if (this.dashes.length == 0) {
            return 10.0;
        }
        else {
            let ret = 0.0;
            for (let i = 0; i < this.dashes.length; i++) {
                ret += Math.abs(this.dashes[i]);
            }
            return ret;
        }
    }
    hasDots() {
        for (let i = 0; i < this.dashes.length; i++) {
            if (ltmath_1.LTMath.fuzzyCompare(this.dashes[i], 0.0, 1.0e-6)) {
                return true;
            }
        }
        return false;
    }
}
exports.RPatternLine = RPatternLine;
class RPattern {
    constructor() {
        this._name = "";
        this.patternLines = [];
    }
    get name() {
        return this._name;
    }
    set name(n) {
        this._name = n;
    }
    clone() {
        let pattern = new RPattern();
        pattern._name = this._name;
        this.patternLines.forEach(element => {
            pattern.patternLines.push(element.clone());
        });
        return pattern;
    }
    setPatternString(str) {
        //解析
        let reg = new RegExp(";");
        let reg1 = new RegExp(",");
        let parts = str.split(reg);
        for (var i = 0; i < parts.length; i++) {
            var part = parts[i];
            let number = part.split(reg1);
            let pline = new RPatternLine();
            pline.angle = Number(number[0]) * 1;
            pline.basePoint = new LTPoint(Number(number[1]), Number(number[2]));
            pline.offset = new LTPoint(Number(number[3]), Number(number[4]));
            for (let j = 5; j < number.length; j++) {
                pline.dashes.push(Number(number[j]));
            }
            this.patternLines.push(pline);
        }
    }
    getName() {
        return this.name;
    }
    hasDots() {
        for (let i = 0; i < this.patternLines.length; ++i) {
            if (this.patternLines[i].hasDots()) {
                return true;
            }
        }
        return false;
    }
    scale(f) {
        this.patternLines.forEach(pattern => {
            pattern.scale(f);
        });
    }
    rotate(a) {
        for (let i = 0; i < this.patternLines.length; ++i) {
            this.patternLines[i].rotate(a);
        }
    }
}
exports.RPattern = RPattern;
