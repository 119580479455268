"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawRectangle = exports.drawPolygon = exports.drawline = exports.drawPolyline = exports.drawRoundPolyline = void 0;
const commandfactory_1 = require("../map/commandfactory");
const ltentity_js_1 = require("../cad/ltentity.js");
const ltpoint_1 = require("../cad/ltpoint");
const ltfeature_js_1 = require("../data/ltfeature.js");
const ltutils_1 = require("../cad/ltutils");
const ltfields_1 = require("../data/ltfields");
const drawutils_1 = require("./drawutils");
const ltmath_1 = require("../cad/ltmath");
const segment_1 = require("../cad/segment");
class DrawLineCommandImpl {
    constructor(map, is_draw_polygon) {
        this._map = {};
        this._tracking_line = null;
        this._is_draw_polygon = false;
        this._map = map;
        this._lineEntity = new ltentity_js_1.LTPolyline();
        this._tracking_line = new ltentity_js_1.LTPolyline();
        this._is_draw_polygon = is_draw_polygon;
    }
    set lineEntity(n) {
        this._lineEntity = n;
    }
    get lineEntity() {
        return this._lineEntity;
    }
    get lastPoint() {
        if (this._lineEntity && this._lineEntity.subData().vertices.length > 0) {
            return this._lineEntity.subData().vertices[this._lineEntity.subData().vertices.length - 1];
        }
        return null;
    }
    _clear() {
        if (this._tracking_line != null) {
            drawutils_1.DrawUtilImpl.destroyEntity(this._map, this._tracking_line);
        }
        if (this._lineEntity !== null) {
            drawutils_1.DrawUtilImpl.destroyEntity(this._map, this._lineEntity);
        }
    }
    destroy() {
        this._clear();
    }
    addPoint(map_pos) {
        //右键返回
        if (this._lineEntity === null) {
            return;
        }
        let vertices = this._lineEntity.subData().vertices;
        this._lineEntity.addPoint(map_pos);
        drawutils_1.DrawUtilImpl.trackEntity(this._map, this._lineEntity);
    }
    trackLine(pos) {
        if (this._lineEntity === null) {
            return;
        }
        var start_point = this._lineEntity.subData().vertices.slice(-1);
        this.trackMoveingLine(start_point[0], pos);
    }
    save(is_line, param_map) {
        if (this._lineEntity == null) {
            return;
        }
        //保存
        if (this._lineEntity.subData().vertices.length <= 1) {
            return;
        }
        if (this._is_draw_polygon) {
            this._lineEntity.subData().setClose();
        }
        let layer = this._map.activeLayer();
        if (param_map && param_map.layername !== undefined) {
            let atlayer = this._map.getLayerByName(param_map.layername);
            if (atlayer !== null) {
                layer = atlayer;
            }
        }
        if (is_line) {
            //每两段线新建一个feature;
            this._map.undoStack().beginMacro("绘制线");
            let vertices = this._lineEntity.subData().vertices;
            for (let i = 0; i < vertices.length - 1; i++) {
                let line = new ltentity_js_1.LTLine(vertices[i], vertices[i + 1]);
                let feature = new ltfeature_js_1.LTFeature({
                    geometry: line,
                    layer: layer
                });
                feature.setValue(ltfields_1.FieldNames.EntityLayer, layer.name);
                feature.setValue(ltfields_1.FieldNames.Linetype, ltentity_js_1.dwg_bylayer);
                feature.setValue(ltfields_1.FieldNames.EntityType, "AcDbLine");
                feature.setValue(ltfields_1.FieldNames.Color, ltentity_js_1.dwg_bylayer);
                feature.setValue(ltfields_1.FieldNames.Width, 0.0);
                this._map.addFeature(feature, true);
            }
            this._map.undoStack().endMacro();
        }
        else {
            let feature = new ltfeature_js_1.LTFeature({
                geometry: this._lineEntity.clone(),
                layer: layer
            });
            //线
            feature.setValue(ltfields_1.FieldNames.EntityLayer, layer.name);
            feature.setValue(ltfields_1.FieldNames.Linetype, ltentity_js_1.dwg_bylayer);
            //this._feature.setValue(FieldNames.EntityType, "AcDbPolyline");
            feature.setValue(ltfields_1.FieldNames.Color, ltentity_js_1.dwg_bylayer);
            feature.setValue(ltfields_1.FieldNames.Width, 0.0);
            this._map.addFeature(feature, true);
        }
        this._clear();
    }
    trackMoveingLine(start_point, move_point) {
        if (this._tracking_line == null) {
            return;
        }
        let points = [];
        points.push(start_point);
        points.push(move_point);
        //中间点;
        let scene_point = this._map.toScreenPoint((start_point.x + move_point.x) * 0.5, (start_point.y + move_point.y) * 0.5);
        this._map.setDistanceEditValue(scene_point.x, scene_point.y, start_point.getDistanceTo(move_point));
        if (this._is_draw_polygon && this._lineEntity && this._lineEntity.subData().vertices.length >= 2) {
            points.push(this._lineEntity.subData().vertices[0]);
        }
        this._tracking_line.subData().vertices = points;
        drawutils_1.DrawUtilImpl.trackEntity(this._map, this._tracking_line);
    }
    //放弃;
    undo() {
        if (this._lineEntity == null) {
            return;
        }
        if (this._lineEntity.subData().vertices.length > 1) {
            this._lineEntity.subData().removeLastVertex();
            drawutils_1.DrawUtilImpl.trackEntity(this._map, this._lineEntity);
        }
    }
    getButtonTip() {
        if (this._lineEntity == null) {
            return "";
        }
        let command_button_tip = [];
        let size = this._lineEntity.subData().vertices.length;
        if (size >= 2) {
            if (!this._is_draw_polygon) {
                command_button_tip.push("闭合(C)");
            }
        }
        if (size >= 1) {
            command_button_tip.push("放弃(U)");
        }
        let button_tip = command_button_tip.join(' ');
        return button_tip;
    }
}
//绘制圆角折线;
function* drawRoundPolyline(map, param_map) {
    commandfactory_1.CommandFactory.getInstance().execCommand("select", map);
    let input_feature = yield;
    if (input_feature.done == commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let feature = input_feature.value;
    if (feature.length == 0) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let line = feature[0].getGeometry();
    let points = line.subData().vertices;
    let radius = 100;
    let clone_points = [];
    let clone_line = new segment_1.RPolyline(clone_points);
    let start_point = points[0];
    for (let i = 0; i < points.length - 2; i += 1) {
        let length = start_point.getDistanceTo(points[i + 1]);
        radius = length * 0.1;
        //计算角度;
        let angle1 = start_point.getAngleTo(points[i + 1]);
        let angle2 = points[i + 1].getAngleTo(points[i + 2]);
        //角度差;
        let angle = ltmath_1.LTMath.getAngleDifference(angle1, angle2);
        if (angle > Math.PI) {
            angle = angle - Math.PI;
        }
        let first_length = radius * Math.tan(angle / 2);
        let start_arc_point = start_point.plus(ltpoint_1.LTPoint.createPolar(length - first_length, angle1));
        if (i == 0) {
            clone_line.appendVertex(start_point);
        }
        let end_arc_point = points[i + 1].plus(ltpoint_1.LTPoint.createPolar(first_length, angle2));
        let sub_arc = segment_1.RArc.createTangential1(start_arc_point, points[i + 1].x, points[i + 1].y, end_arc_point.x, end_arc_point.y, radius);
        clone_line.appendVertex(start_arc_point, sub_arc.getBulge());
        clone_line.appendVertex(end_arc_point, 0);
        start_point = end_arc_point.clone();
    }
    clone_line.appendVertex(points[points.length - 1], 0);
    let ent = ltutils_1.LTUtils.shapeToEntity(clone_line);
    if (ent) {
        drawutils_1.DrawUtilImpl.trackEntity(map, ent);
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawRoundPolyline = drawRoundPolyline;
function* drawPolyline(map, command_param) {
    let is_draw_polygon = (command_param === null || command_param === void 0 ? void 0 : command_param.is_draw_polygon) || false;
    let draw_command = new DrawLineCommandImpl(map, is_draw_polygon);
    let tip = "";
    if (command_param !== undefined && command_param !== null && command_param.tip !== undefined) {
        tip = command_param.tip;
    }
    let noSaveFeature = (command_param === null || command_param === void 0 ? void 0 : command_param.noSaveFeature) || false;
    commandfactory_1.CommandFactory.getInstance().getPoint(map, tip + "请输入起点:");
    let start_mouse = yield;
    if (start_mouse.done == commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    function drawCallback(mouse) {
        //计算中间位置;
        draw_command.trackLine(mouse.mapPos);
    }
    draw_command.addPoint(start_mouse.value);
    while (start_mouse.done != commandfactory_1.CommandStatus.eDone) {
        let button_tip = draw_command.getButtonTip();
        let snap_feature = new ltfeature_js_1.LTFeature({ geometry: draw_command.lineEntity });
        map.snap.setFeature(snap_feature);
        commandfactory_1.CommandFactory.getInstance().getPoint(map, tip + `请输入下一个点[${button_tip}]:`, drawCallback, draw_command.lastPoint);
        start_mouse = yield;
        if (start_mouse.done == commandfactory_1.CommandStatus.eCancel) {
            if (noSaveFeature == false) {
                draw_command.save(false, command_param);
            }
            draw_command.destroy();
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        if (start_mouse.done == commandfactory_1.CommandStatus.eDone) {
            break;
        }
        else if (start_mouse.done == commandfactory_1.CommandStatus.eKeyWord) {
            let key = start_mouse.value;
            key = key.toUpperCase();
            if (key == "C") {
                if (draw_command.lineEntity) {
                    draw_command.addPoint(draw_command.lineEntity.subData().vertices[0]);
                    break;
                }
            }
            else if (key == "U") {
                draw_command.undo();
            }
        }
        else {
            draw_command.addPoint(start_mouse.value);
        }
    }
    let lineEntity = draw_command.lineEntity;
    if (is_draw_polygon && lineEntity) {
        lineEntity.subData().setClose();
    }
    drawRoundPolyline(map, draw_command.lineEntity);
    if (noSaveFeature == false) {
        draw_command.save(false, command_param);
    }
    draw_command.destroy();
    if (lineEntity == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    if (lineEntity.subData().vertices.length > 1) {
        return { value: lineEntity, done: commandfactory_1.CommandStatus.eDone };
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawPolyline = drawPolyline;
function* drawline(map, command_param) {
    let draw_command = new DrawLineCommandImpl(map, false);
    let tip = "";
    if (command_param !== undefined && command_param !== null && command_param.tip !== undefined) {
        tip = command_param.tip;
    }
    function drawCallback(mouse) {
        draw_command.trackLine(mouse.mapPos);
    }
    let start_mouse;
    if (command_param !== undefined && command_param !== null && command_param.lineEntity !== undefined) {
        draw_command.lineEntity = command_param.lineEntity;
        start_mouse = { value: null, done: commandfactory_1.CommandStatus.eOK };
    }
    else {
        commandfactory_1.CommandFactory.getInstance().getPoint(map, tip + "请输入起点:");
        start_mouse = yield;
        if (start_mouse.done == commandfactory_1.CommandStatus.eCancel) {
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        draw_command.addPoint(start_mouse.value);
    }
    let vertex_index = draw_command.lineEntity.subData().vertices.length;
    while (start_mouse.done != commandfactory_1.CommandStatus.eDone) {
        let command_button_tip = [];
        if (vertex_index >= 2) {
            command_button_tip.push("闭合(C)");
        }
        if (vertex_index > 1) {
            command_button_tip.push("放弃(U)");
        }
        let button_tip = command_button_tip.join(' ');
        let snap_feature = new ltfeature_js_1.LTFeature({ geometry: draw_command.lineEntity });
        map.snap.setFeature(snap_feature);
        commandfactory_1.CommandFactory.getInstance().getPoint(map, tip + `请输入下一个点[${button_tip}]:`, drawCallback, draw_command.lastPoint);
        vertex_index += 1;
        start_mouse = yield;
        if (start_mouse.done == commandfactory_1.CommandStatus.eCancel) {
            let noSaveFeature = (command_param === null || command_param === void 0 ? void 0 : command_param.noSaveFeature) || false;
            if (noSaveFeature == false) {
                draw_command.save(true, command_param);
            }
            draw_command.destroy();
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        if (start_mouse.done == commandfactory_1.CommandStatus.eDone) {
            break;
        }
        else if (start_mouse.done == commandfactory_1.CommandStatus.eKeyWord) {
            if (start_mouse.value.toUpperCase() == "C") {
                if (draw_command.lineEntity) {
                    draw_command.addPoint(draw_command.lineEntity.subData().vertices[0]);
                    break;
                }
            }
            else if (start_mouse.value.toUpperCase() == "U") {
                draw_command.undo();
            }
        }
        else {
            draw_command.addPoint(start_mouse.value);
        }
    }
    let lineEntity = draw_command.lineEntity;
    let noSaveFeature = (command_param === null || command_param === void 0 ? void 0 : command_param.noSaveFeature) || false;
    if (noSaveFeature == false) {
        draw_command.save(true, command_param);
    }
    draw_command.destroy();
    if (lineEntity == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    if (lineEntity.subData().vertices.length > 1) {
        return { value: lineEntity, done: commandfactory_1.CommandStatus.eDone };
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawline = drawline;
function* drawPolygon(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().getNumber(map, "请输入边数<3>:");
    let input_edge_count = yield;
    if (input_edge_count.done == commandfactory_1.CommandStatus.eCancel || input_edge_count.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: input_edge_count.done };
    }
    let edge_count = input_edge_count.value;
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定正多边形的中心点:");
    let input_center_point = yield;
    if (input_center_point.done == commandfactory_1.CommandStatus.eCancel || input_center_point.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: input_center_point.done };
    }
    let center_point = input_center_point.value;
    let polygon = null;
    //根据中点和边的中点创建多边形;
    function createPolygon1(center, map_pos) {
        let side_point = map_pos;
        let angle = Math.PI / edge_count;
        let corner;
        let is_side_draw = false;
        if (is_side_draw) {
            let dist = center.getDistanceTo(side_point);
            let opp = Math.tan(angle) * dist;
            let hyp = Math.sqrt((dist * dist) + (opp * opp));
            let v = ltpoint_1.LTPoint.createPolar(hyp, center.getAngleTo(side_point) + angle);
            corner = center.plus(v);
        }
        else {
            corner = side_point;
        }
        let corners = [];
        for (let n = 1; n <= edge_count; ++n) {
            let c = side_point.clone();
            c.rotate((Math.PI * 2.0) / edge_count * n, center_point);
            corners.push(c);
        }
        if (polygon == null) {
            polygon = new ltentity_js_1.LTPolyline(corners);
        }
        polygon.subData().setVertices(corners);
        polygon.subData().setClose();
    }
    function createPolygon2(corner1, corner2) {
        {
            var len = corner1.getDistanceTo(corner2);
            var ang1 = corner1.getAngleTo(corner2);
            var ang = ang1;
            let corners = [];
            let c = corner1;
            for (var n = 1; n <= edge_count; ++n) {
                var edge = new ltpoint_1.LTPoint(0, 0);
                edge.setPolar(len, ang);
                corners.push(c.clone());
                c = c.plus(edge);
                ang = ang1 + (2 * Math.PI) / edge_count * n;
            }
            if (polygon == null) {
                polygon = new ltentity_js_1.LTPolyline(corners);
            }
            polygon.subData().setVertices(corners);
            polygon.subData().setClose();
        }
    }
    function drawCallback(mouse) {
        createPolygon1(center_point, mouse.mapPos);
        if (polygon !== null) {
            drawutils_1.DrawUtilImpl.trackEntity(map, polygon);
        }
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定正多边形的边上一点:", drawCallback);
    let input_side_point = yield;
    if (input_side_point.done == commandfactory_1.CommandStatus.eCancel || input_side_point.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: input_side_point.done };
    }
    let side_point = input_side_point.value;
    createPolygon1(center_point, side_point);
    if (polygon !== null) {
        drawutils_1.DrawUtilImpl.saveEntity(map, polygon);
        drawutils_1.DrawUtilImpl.destroyEntity(map, polygon);
    }
    return { value: polygon, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawPolygon = drawPolygon;
function* drawRectangle(map, command_param) {
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定矩形的第一个角点:");
    let input_corner1 = yield;
    if (input_corner1.done == commandfactory_1.CommandStatus.eCancel || input_corner1.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: input_corner1.done };
    }
    let corner1 = input_corner1.value;
    let polygon = null;
    function drawCallback(mouse) {
        let box = ltpoint_1.RBox.formVectors(corner1, mouse.mapPos);
        let rectangle = ltutils_1.LTUtils.boxToPolyline(box);
        if (polygon !== null) {
            drawutils_1.DrawUtilImpl.destroyEntity(map, polygon);
        }
        polygon = rectangle;
        drawutils_1.DrawUtilImpl.trackEntity(map, polygon);
    }
    while (true) {
        commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定矩形的另一个角点[尺寸(D)]<D>:", drawCallback);
        let input_corner2 = yield;
        if (input_corner2.done == commandfactory_1.CommandStatus.eCancel || input_corner2.done == commandfactory_1.CommandStatus.eDone) {
            if (polygon !== null) {
                drawutils_1.DrawUtilImpl.destroyEntity(map, polygon);
            }
            return { value: null, done: input_corner2.done };
        }
        if (input_corner2.done == commandfactory_1.CommandStatus.eKeyWord) {
            if (input_corner2.value.toLowerCase() === "d") {
                if (polygon !== null) {
                    drawutils_1.DrawUtilImpl.destroyEntity(map, polygon);
                }
                let width, height;
                commandfactory_1.CommandFactory.getInstance().getNumber(map, "指定矩形的长度<100.00>:");
                let height_yelid = yield;
                if (height_yelid.done == commandfactory_1.CommandStatus.eCancel) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                else if (height_yelid.done == commandfactory_1.CommandStatus.eDone) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                height = height_yelid.value;
                commandfactory_1.CommandFactory.getInstance().getNumber(map, "指定矩形的宽度<100.00>:");
                let width_yelid = yield;
                if (width_yelid.done == commandfactory_1.CommandStatus.eCancel) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                else if (width_yelid.done == commandfactory_1.CommandStatus.eDone) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                width = width_yelid.value;
                let corner2 = new ltpoint_1.LTPoint(corner1.x + width, corner1.y + height);
                let box = ltpoint_1.RBox.formVectors(corner1, corner2);
                let rectangle = ltutils_1.LTUtils.boxToPolyline(box);
                drawutils_1.DrawUtilImpl.saveEntity(map, rectangle);
                if (polygon !== null) {
                    drawutils_1.DrawUtilImpl.destroyEntity(map, polygon);
                }
                return { value: rectangle, done: commandfactory_1.CommandStatus.eDone };
            }
            else {
                continue;
            }
        }
        let corner2 = input_corner2.value;
        let box = ltpoint_1.RBox.formVectors(corner1, corner2);
        let rectangle = ltutils_1.LTUtils.boxToPolyline(box);
        drawutils_1.DrawUtilImpl.saveEntity(map, rectangle);
        if (polygon !== null) {
            drawutils_1.DrawUtilImpl.destroyEntity(map, polygon);
        }
        return { value: rectangle, done: commandfactory_1.CommandStatus.eDone };
    }
}
exports.drawRectangle = drawRectangle;
