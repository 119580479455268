"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTUtils = exports.ButtonStatus = exports.AngleTolerance = exports.PointTolerance = exports.InputType = exports.MapCursorType = exports.ShapeType = void 0;
const ltpoint_1 = require("./ltpoint");
const ltentity_1 = require("./ltentity");
const segment_1 = require("./segment");
const dimension_1 = require("./dimension");
const lodash_1 = require("lodash");
var ShapeType;
(function (ShapeType) {
    ShapeType[ShapeType["Unknown"] = -1] = "Unknown";
    ShapeType[ShapeType["Point"] = 0] = "Point";
    ShapeType[ShapeType["Line"] = 1] = "Line";
    ShapeType[ShapeType["Arc"] = 2] = "Arc";
    ShapeType[ShapeType["Circle"] = 3] = "Circle";
    ShapeType[ShapeType["Ellipse"] = 4] = "Ellipse";
    ShapeType[ShapeType["Polyline"] = 5] = "Polyline";
    ShapeType[ShapeType["Text"] = 6] = "Text";
    ShapeType[ShapeType["RotateDim"] = 7] = "RotateDim";
    ShapeType[ShapeType["Hatch"] = 8] = "Hatch";
    ShapeType[ShapeType["Spline"] = 9] = "Spline";
})(ShapeType || (exports.ShapeType = ShapeType = {}));
;
//地图的鼠标样式;
var MapCursorType;
(function (MapCursorType) {
    MapCursorType[MapCursorType["CursorDefault"] = 0] = "CursorDefault";
    MapCursorType[MapCursorType["CursorMove"] = 1] = "CursorMove";
    MapCursorType[MapCursorType["CursorInCommand"] = 2] = "CursorInCommand";
    MapCursorType[MapCursorType["CursorSingleSelect"] = 3] = "CursorSingleSelect";
})(MapCursorType || (exports.MapCursorType = MapCursorType = {}));
;
//交互输入类型;
var InputType;
(function (InputType) {
    InputType[InputType["None"] = 0] = "None";
    InputType[InputType["InputPoint"] = 1] = "InputPoint";
    InputType[InputType["InputNumber"] = 2] = "InputNumber";
    InputType[InputType["InputAngle"] = 4] = "InputAngle";
    InputType[InputType["InputDistance"] = 8] = "InputDistance";
    InputType[InputType["InputString"] = 16] = "InputString";
    InputType[InputType["InputPointAndString"] = 17] = "InputPointAndString";
})(InputType || (exports.InputType = InputType = {}));
exports.PointTolerance = 1e-9;
exports.AngleTolerance = 1e-9;
var ButtonStatus;
(function (ButtonStatus) {
    ButtonStatus[ButtonStatus["LeftButton"] = 0] = "LeftButton";
    ButtonStatus[ButtonStatus["MiddleButton"] = 1] = "MiddleButton";
    ButtonStatus[ButtonStatus["RightButton"] = 2] = "RightButton";
})(ButtonStatus || (exports.ButtonStatus = ButtonStatus = {}));
var EPS = 1.0e-10;
/**
 * LTUtils 工具类 主要是几何算法
 */
class LTUtils {
    /**
     * \brief 计算线段角度
     * \param startPoint 开始点
     * \param endPoint 结束点
     */
    static angle(startPoint, endPoint) {
        var dx = endPoint.x - startPoint.x;
        var dy = endPoint.y - startPoint.y;
        if (dx == 0 && dy == 0) {
            return 0.0;
        }
        var angle = Math.atan2(dy, dx);
        if (angle < 0) {
            return angle + Math.PI * 2;
        }
        else {
            return angle;
        }
    }
    /**
     * \brief 求向量的单位向量
     * \param v 向量
     */
    static normalizedPoint(v) {
        let is_x_null = Math.abs(v.getx()) < EPS;
        let is_y_null = Math.abs(v.gety()) < EPS;
        if (is_x_null && is_y_null) {
            return new ltpoint_1.LTPoint(0.0, 0.0);
        }
        else if (is_x_null) {
            if (v.gety() > 0) {
                return new ltpoint_1.LTPoint(0.0, 1.0);
            }
            return new ltpoint_1.LTPoint(0.0, -1.0);
        }
        else if (is_y_null) {
            if (v.getx() > 0) {
                return new ltpoint_1.LTPoint(1.0, 0.0);
            }
            return new ltpoint_1.LTPoint(-1.0, 0.0);
        }
        let dist = Math.sqrt(v.getx() * v.getx() + v.gety() * v.gety());
        return new ltpoint_1.LTPoint(v.getx() / dist, v.gety() / dist);
    }
    static containsByPoint(nodes, pos) {
        let j = nodes.length - 1;
        let is_contain = false;
        for (let i = 0; i < nodes.length; i++) {
            if (((nodes[i].y <= pos.y && nodes[j].y >= pos.y) ||
                (nodes[j].y <= pos.y && nodes[i].y >= pos.y)) &&
                (nodes[i].x <= pos.x || nodes[j].x <= pos.x)) {
                let val = nodes[i].x +
                    ((pos.y - nodes[i].y) /
                        (nodes[j].y - nodes[i].y)) *
                        (nodes[j].x - nodes[i].x) <
                    pos.x;
                if (is_contain === val) {
                    is_contain = false;
                }
                else {
                    is_contain = true;
                }
            }
            j = i;
        }
        return is_contain;
    }
    static replace(arr, item1, item2) {
        return arr.map((item) => item === item1 ? item2 : item);
    }
    static isRectangle(x1, y1, x2, y2, x3, y3, x4, y4) {
        const distanceAB = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        const distanceCD = Math.sqrt(Math.pow(x4 - x3, 2) + Math.pow(y4 - y3, 2));
        const distanceAC = Math.sqrt(Math.pow(x3 - x1, 2) + Math.pow(y3 - y1, 2));
        const distanceBD = Math.sqrt(Math.pow(x4 - x2, 2) + Math.pow(y4 - y2, 2));
        return distanceAB === distanceCD && distanceAC === distanceBD;
    }
    static jsonPoint2Point(points) {
        let pos = [];
        for (let i = 0; i < points.length; i++) {
            let p = points[i];
            pos.push(new ltpoint_1.LTPoint(Number(p.x), Number(p.y)));
        }
        return pos;
    }
    static json2Point(json) {
        let points = json.points;
        return this.jsonPoint2Point(points);
    }
    static json2klineseg2d(ent) {
        let start = new ltpoint_1.LTPoint(Number(ent.startPoint.x), Number(ent.startPoint.y));
        let end = new ltpoint_1.LTPoint(Number(ent.endPoint.x), Number(ent.endPoint.y));
        let line = new segment_1.RLine(start, end);
        return line;
    }
    static json2circle(ent) {
        let center = new ltpoint_1.LTPoint(Number(ent.center.x), Number(ent.center.y));
        let radius = Number(ent.radius);
        let circle = new segment_1.RCircle(center, radius);
        return circle;
    }
    static json2Hatch(json, hatch) {
        for (let i = 0; i < json.length; i++) {
            let loop = json[i];
            if (loop === undefined || loop === null) {
                continue;
            }
            if (loop.entitytype === "acdbcircle") {
                let circle = this.json2circle(loop);
                hatch.subData().addloops(circle);
                continue;
            }
            if (loop.points === null) {
                continue;
            }
            let pl = new ltentity_1.LTPolyline();
            this.json2Polyline(loop, pl);
            hatch.subData().addloops(pl.subData());
        }
    }
    static wkt2Entity(wkt) {
        var wkx = require("wkx");
        let geometry = wkx.Geometry.parse(wkt.toUpperCase());
        if (geometry == null) {
            return null;
        }
        var dwg_points = [];
        if (geometry.lineStrings != null) {
            var points = geometry.lineStrings[0].points;
            for (var m = 0; m < points.length; ++m) {
                dwg_points.push(new ltpoint_1.LTPoint(points[m].x, points[m].y));
            }
            let pl = new ltentity_1.LTPolyline(dwg_points);
            return pl;
        }
        return null;
    }
    static json2Entity(info) {
        //颜色
        let color = info.color;
        //线型
        let linetype = info.linetype;
        //宽度
        let size = info.width;
        //图层
        let layer = info.entitylayer;
        //图层
        let entitytype = info.entitytype;
        if (info.cadgeo == undefined || info.cadgeo == "") {
            return null;
        }
        let cadgeo_entitytype = "";
        let json_info = JSON.parse(info.cadgeo);
        if (json_info.hidden !== undefined) {
            //对应隐藏的图形执行不渲染;
            return null;
        }
        let result_entity = null;
        if (info.cadgeo !== undefined) {
            cadgeo_entitytype = json_info.entitytype;
        }
        if (entitytype.toLowerCase() == "acdbtext" ||
            entitytype.toLowerCase() == "acdbmtext" ||
            entitytype.toLowerCase() == "acdbattributedefinition") {
            let pos = this.json2Point(json_info)[0];
            if (pos === undefined) {
                return null;
            }
            var text = new ltentity_1.LTText(pos);
            if (json_info.defaultalignment != undefined && json_info.defaultalignment === true) {
                text.setPoint(new ltpoint_1.LTPoint(json_info.alignmentx * 1, json_info.alignmenty * 1));
            }
            text.setIsSub(true);
            text.setLayerName(layer);
            text.setColorInfo(color);
            text.setRotate(info.rotate * 1.0);
            var text_val = info.text;
            if (typeof text_val == "undefined") {
                return null;
            }
            text.text = text_val;
            text.setHeight(info.width * 1.0);
            text.setFontInfo(info.info);
            result_entity = text;
        }
        else if (entitytype.toLowerCase() == "acdbblockreference") {
            if (json_info[0].hidden !== undefined) {
                return null;
            }
            let pos = this.jsonPoint2Point(json_info);
            var entityPoint = new ltentity_1.LTEntityPoint(pos[0]);
            entityPoint.setIsSub(true);
            entityPoint.setLayerName(info.entitylayer);
            entityPoint.setColorInfo(info.color);
            entityPoint.setRotate(info.rotate * 1.0);
            entityPoint.blockName = info.blockname;
            entityPoint.setBlockInfo(info.info);
            result_entity = entityPoint;
        }
        else if (entitytype.toLowerCase() == "acdbrotateddimension") {
            let rotate_dim = this.toRotateDimension(info);
            rotate_dim.setIsSub(true);
            rotate_dim.setLayerName(layer);
            rotate_dim.setColorInfo(color);
            result_entity = rotate_dim;
        }
        else if (entitytype.toLowerCase() == "acdb3pointangulardimension") {
            let rotate_dim = this.toAngular3PDimension(info);
            rotate_dim.setIsSub(true);
            rotate_dim.setLayerName(layer);
            rotate_dim.setColorInfo(color);
            result_entity = rotate_dim;
        }
        else if (entitytype.toLowerCase() == "acdbaligneddimension") {
            let rotate_dim = this.toAlignedDimension(info);
            rotate_dim.setIsSub(true);
            rotate_dim.setLayerName(layer);
            rotate_dim.setColorInfo(color);
            result_entity = rotate_dim;
        }
        else if (entitytype.toLowerCase() == "acdbellipse") {
            let ellipse_entity = this.toEllipse(info);
            ellipse_entity.setIsSub(true);
            ellipse_entity.setLayerName(layer);
            ellipse_entity.setColorInfo(color);
            ellipse_entity.setLineType(linetype);
            ellipse_entity.setStrokeWidth(size);
            result_entity = ellipse_entity;
        }
        else if (entitytype.toLowerCase() == "acdbcircle" || cadgeo_entitytype == "acdbcircle") {
            let circle = this.toCircle(info);
            circle.setIsSub(true);
            circle.setLayerName(layer);
            circle.setColorInfo(color);
            circle.setLineType(linetype);
            circle.setStrokeWidth(size);
            result_entity = circle;
        }
        else if (entitytype.toLowerCase() == "acdbspline") {
            let spline = this.toSpline(info);
            spline.setIsSub(true);
            spline.setLayerName(layer);
            spline.setColorInfo(color);
            spline.setLineType(linetype);
            spline.setStrokeWidth(size);
            result_entity = spline;
        }
        else if (entitytype.toLowerCase() == "acdbhatch") {
            let hatch = this.toHatch(json_info, info);
            if (Object.keys(hatch).length === 0) {
                return null;
            }
            hatch.setIsSub(true);
            hatch.setLayerName(layer);
            hatch.setColorInfo(color);
            hatch.hatchName = info.blockname;
            result_entity = hatch;
        }
        else if (entitytype.toLowerCase() == "acdbline") {
            let r = this.json2Point(json_info);
            let start = r[0];
            let end = r[1];
            if (start === undefined || end === undefined) {
                return null;
            }
            let dwg_entity = new ltentity_1.LTLine(start, end);
            dwg_entity.setIsSub(true);
            dwg_entity.setLayerName(layer);
            dwg_entity.setColorInfo(color);
            dwg_entity.setLineType(linetype);
            dwg_entity.setStrokeWidth(size);
            result_entity = dwg_entity;
        }
        else if (entitytype.toLowerCase() == "acdbpolyline" || entitytype.toLowerCase() == "acdb2dpolyline") {
            let pl = new ltentity_1.LTPolyline();
            this.json2Polyline(json_info, pl);
            pl.setIsSub(true);
            pl.setLayerName(layer);
            pl.setColorInfo(color);
            pl.setLineType(linetype);
            pl.setStrokeWidth(size);
            result_entity = pl;
        }
        else if (entitytype.toLowerCase() == "acdbsolid") {
            let hatch = this.toHatch(json_info, info);
            if (Object.keys(hatch).length === 0) {
                return null;
            }
            hatch.setIsSub(true);
            hatch.setLayerName(layer);
            hatch.setColorInfo(color);
            hatch.hatchName = info.blockname;
            result_entity = hatch;
        }
        else if (entitytype.toLowerCase() == "acdbarc") {
            var center = json_info.center;
            var arc_entity = new ltentity_1.LTArc(new ltpoint_1.LTPoint(Number(center.x), Number(center.y)), Number(json_info.radius), Number(json_info.startangle), Number(json_info.endangle), Boolean(json_info.reversed));
            arc_entity.setIsSub(true);
            arc_entity.setLayerName(layer);
            arc_entity.setColorInfo(color);
            arc_entity.setStrokeWidth(size);
            arc_entity.subData()._uAxisx = json_info.uAxisx;
            arc_entity.subData()._uAxisy = json_info.uAxisy;
            if (json_info.uAxisx == -1) {
                arc_entity.subData().flipHorizontal();
            }
            result_entity = arc_entity;
        }
        else if (entitytype.toLowerCase() == "acdbwipeout") {
            if (json_info.length == 1) {
                let loop = json_info[0];
                let pl = new ltentity_1.LTPolyline();
                this.json2Polyline(loop, pl);
                pl.subData().isWipeOut = true;
                pl.setIsSub(true);
                pl.setLayerName(layer);
                pl.setColorInfo(color);
                pl.setStrokeWidth(size);
                result_entity = pl;
            }
            else {
                let hatch = this.toHatch(json_info, info);
                if (Object.keys(hatch).length === 0) {
                    return null;
                }
                hatch.setIsSub(true);
                hatch.setLayerName(layer);
                hatch.setColorInfo(color);
                hatch.hatchName = info.blockname;
                result_entity = hatch;
            }
        }
        return result_entity;
    }
    static setProperties(entity, properties) {
        entity.setLayerName(properties.entitylayer);
        entity.setColorInfo(properties.color);
        entity.setLineType(properties.linetype);
        entity.setRotate(properties.rotate * 1.0);
        entity.setStrokeWidth(properties.width);
        let linetypesize = 1;
        if (properties.info !== undefined && properties.info !== "") {
            let geo_json = JSON.parse(properties.info);
            if (!(0, lodash_1.isNull)(geo_json) && !(0, lodash_1.isNull)(geo_json.linetypesize)) {
                linetypesize = geo_json.linetypesize;
            }
        }
        entity.setLineTypeScale(linetypesize);
    }
    static toText(p, properties) {
        var text = new ltentity_1.LTText(p);
        this.setProperties(text, properties);
        var text_val = properties.text;
        if (typeof text_val == "undefined") {
            return text;
        }
        text.text = text_val;
        text.setHeight(properties.width * 1.0);
        text.setFontInfo(properties.info);
        return text;
    }
    static toRotateDimension(properties) {
        let cad_geo_info = properties.cadgeo;
        let geo_json = JSON.parse(cad_geo_info);
        let ext1point = new ltpoint_1.LTPoint(geo_json.ext1pos.x * 1, geo_json.ext1pos.y * 1);
        let ext2point = new ltpoint_1.LTPoint(geo_json.ext2pos.x * 1, geo_json.ext2pos.y * 1);
        let dim_pos = new ltpoint_1.LTPoint(geo_json.dimpos.x * 1, geo_json.dimpos.y * 1);
        let text = geo_json.textinfo.text;
        let height = geo_json.textinfo.height * 1;
        let text_position = new ltpoint_1.LTPoint(geo_json.textinfo.x * 1, geo_json.textinfo.y * 1);
        let rotate_dim = new ltentity_1.LTDimension(ext1point, ext2point, text, dim_pos, text_position, dimension_1.DimensionType.EntityDimRotated);
        rotate_dim.height = height;
        rotate_dim.subData().setDimStyle(geo_json.name, false);
        rotate_dim.setInfo(geo_json);
        rotate_dim.setRotate(geo_json.rotation);
        this.setProperties(rotate_dim, properties);
        if (geo_json.hidden !== undefined) {
            rotate_dim.visible = false;
        }
        return rotate_dim;
    }
    static toAlignedDimension(properties) {
        let cad_geo_info = properties.cadgeo;
        let geo_json = JSON.parse(cad_geo_info);
        let ext1point = new ltpoint_1.LTPoint(geo_json.ext1pos.x * 1, geo_json.ext1pos.y * 1);
        let ext2point = new ltpoint_1.LTPoint(geo_json.ext2pos.x * 1, geo_json.ext2pos.y * 1);
        let dim_pos = new ltpoint_1.LTPoint(geo_json.dimpos.x * 1, geo_json.dimpos.y * 1);
        let text = geo_json.textinfo.text;
        let height = geo_json.textinfo.height * 1;
        let text_position = new ltpoint_1.LTPoint(geo_json.textinfo.x * 1, geo_json.textinfo.y * 1);
        let rotate_dim = new ltentity_1.LTDimension(ext1point, ext2point, text, dim_pos, text_position, dimension_1.DimensionType.EntityDimAligned);
        rotate_dim.height = height;
        rotate_dim.subData().setDimStyle(geo_json.name, false);
        rotate_dim.setInfo(geo_json);
        rotate_dim.setRotate(geo_json.rotation);
        this.setProperties(rotate_dim, properties);
        if (geo_json.hidden !== undefined) {
            rotate_dim.visible = false;
        }
        return rotate_dim;
    }
    static toAngular3PDimension(properties) {
        let cad_geo_info = properties.cadgeo;
        let geo_json = JSON.parse(cad_geo_info);
        let arcPoint = new ltpoint_1.LTPoint(geo_json.arcPoint.x * 1, geo_json.arcPoint.y * 1);
        let centerPoint = new ltpoint_1.LTPoint(geo_json.centerPoint.x * 1, geo_json.centerPoint.y * 1);
        let xLine1Point = new ltpoint_1.LTPoint(geo_json.xLine1Point.x * 1, geo_json.xLine1Point.y * 1);
        let xLine2Point = new ltpoint_1.LTPoint(geo_json.xLine2Point.x * 1, geo_json.xLine2Point.y * 1);
        let rotate_dim = new ltentity_1.LTDimension(new ltpoint_1.LTPoint(0, 0), new ltpoint_1.LTPoint(0, 0), "", xLine2Point, new ltpoint_1.LTPoint(0, 0), dimension_1.DimensionType.EntityDimAngular3P);
        let angulardata = rotate_dim.subData();
        angulardata.setDimStyle(geo_json.name, true);
        angulardata.setCenter(centerPoint);
        angulardata.setExtensionLine1End(xLine1Point);
        angulardata.setExtensionLine2End(xLine2Point);
        angulardata.setDimArcPosition(arcPoint);
        angulardata.setInfo(geo_json);
        this.setProperties(rotate_dim, properties);
        if (geo_json.hidden !== undefined) {
            rotate_dim.visible = false;
        }
        return rotate_dim;
    }
    static toAngular2LDimension(properties) {
        let cad_geo_info = properties.cadgeo;
        let geo_json = JSON.parse(cad_geo_info);
        let arcPoint = new ltpoint_1.LTPoint(geo_json.arcPoint.x * 1, geo_json.arcPoint.y * 1);
        let xLine1End = new ltpoint_1.LTPoint(geo_json.xLine1End.x * 1, geo_json.xLine1End.y * 1);
        let xLine1Start = new ltpoint_1.LTPoint(geo_json.xLine1Start.x * 1, geo_json.xLine1Start.y * 1);
        let xLine2Start = new ltpoint_1.LTPoint(geo_json.xLine2Start.x * 1, geo_json.xLine2Start.y * 1);
        let xLine2End = new ltpoint_1.LTPoint(geo_json.xLine2End.x * 1, geo_json.xLine2End.y * 1);
        let rotate_dim = new ltentity_1.LTDimension(new ltpoint_1.LTPoint(0, 0), new ltpoint_1.LTPoint(0, 0), "", xLine2End, new ltpoint_1.LTPoint(0, 0), dimension_1.DimensionType.EntityDimAngular2L);
        let angulardata = rotate_dim.subData();
        angulardata.setDimStyle(geo_json.name, true);
        angulardata.setExtensionLine1Start(xLine1Start);
        angulardata.setExtensionLine1End(xLine1End);
        angulardata.setExtensionLine2Start(xLine2Start);
        angulardata.setDimArcPosition(arcPoint);
        angulardata.setInfo(geo_json);
        this.setProperties(rotate_dim, properties);
        if (geo_json.hidden !== undefined) {
            rotate_dim.visible = false;
        }
        return rotate_dim;
    }
    static toCircle(properties) {
        let cad_geo_info = properties.cadgeo;
        let geo_json = JSON.parse(cad_geo_info);
        let center = geo_json.center;
        let circle_entity = new ltentity_1.LTCircle(new ltpoint_1.LTPoint(Number(center.x), Number(center.y)), Number(geo_json.radius));
        this.setProperties(circle_entity, properties);
        if (geo_json.hidden !== undefined) {
            circle_entity.visible = false;
        }
        return circle_entity;
    }
    static json2Ellipse(geo_json) {
        let center = new ltpoint_1.LTPoint(Number(geo_json.center.x), Number(geo_json.center.y));
        let majorPoint = new ltpoint_1.LTPoint(Number(geo_json.majorPoint.x), Number(geo_json.majorPoint.y));
        let radiusRatio = Number(geo_json.radiusRatio);
        let startParam = Number(geo_json.startParam);
        let endParam = Number(geo_json.endParam);
        var ellipse_entity = new ltentity_1.LTEllipse(center, majorPoint, radiusRatio, startParam, endParam, false);
        return ellipse_entity;
    }
    static toEllipse(properties) {
        let cad_geo_info = properties.cadgeo;
        let geo_json = JSON.parse(cad_geo_info);
        let ellipse_entity = this.json2Ellipse(geo_json);
        let linetypesize = 1;
        this.setProperties(ellipse_entity, properties);
        if (geo_json.hidden !== undefined) {
            ellipse_entity.visible = false;
        }
        return ellipse_entity;
    }
    static toDbLine(properties) {
        let geo_json = JSON.parse(properties.cadgeo);
        let r = this.json2Point(geo_json);
        let start = r[0];
        let end = r[1];
        if (start === undefined || end === undefined) {
            return {};
        }
        let dwg_entity = new ltentity_1.LTLine(start, end);
        this.setProperties(dwg_entity, properties);
        if (geo_json.hidden !== undefined) {
            dwg_entity.visible = false;
        }
        return dwg_entity;
    }
    static json2Polyline(geo_json, dwg_entity) {
        let points = geo_json.points;
        for (let i = 0; i < points.length; i++) {
            let blg = Number(points[i].bulges);
            let x = Number(points[i].x);
            let y = Number(points[i].y);
            let start_width = points[i].startwidth | 0;
            let end_width = points[i].endwidth | 0;
            dwg_entity.subData().appendVertex(new ltpoint_1.LTPoint(x, y), blg, start_width, end_width);
        }
        if (geo_json.close == true) {
            dwg_entity.subData().setClose();
        }
    }
    static toDbPolyline(properties) {
        let dwg_entity = new ltentity_1.LTPolyline();
        //加点
        let geo_json = JSON.parse(properties.cadgeo);
        this.json2Polyline(geo_json, dwg_entity);
        this.setProperties(dwg_entity, properties);
        if (geo_json.hidden !== undefined) {
            dwg_entity.visible = false;
        }
        return dwg_entity;
    }
    static toDbBlockReference(properties) {
        let json_info = JSON.parse(properties.cadgeo);
        let point = this.jsonPoint2Point(json_info);
        if (point == undefined) {
            return {};
        }
        var dwg_point = new ltpoint_1.LTPoint(point[0].x, point[0].y);
        var entityPoint = new ltentity_1.LTEntityPoint(dwg_point);
        if (properties.blockname != null) {
            entityPoint.blockName = properties.blockname;
        }
        if (properties.info !== undefined && properties.info != null) {
            entityPoint.setBlockInfo(properties.info);
        }
        this.setProperties(entityPoint, properties);
        entityPoint.setRotate(properties.rotate * 1.0);
        if (json_info[0].hidden !== undefined) {
            entityPoint.visible = false;
        }
        return entityPoint;
    }
    static toArc(properties) {
        var cad_geo_info = properties.cadgeo;
        var geo_json = JSON.parse(cad_geo_info);
        var center = geo_json.center;
        var arc_entity = new ltentity_1.LTArc(new ltpoint_1.LTPoint(Number(center.x), Number(center.y)), Number(geo_json.radius), Number(geo_json.startangle), Number(geo_json.endangle), Boolean(geo_json.reversed));
        this.setProperties(arc_entity, properties);
        if (geo_json.hidden !== undefined) {
            arc_entity.visible = false;
        }
        arc_entity.subData()._uAxisx = geo_json.uAxisx;
        arc_entity.subData()._uAxisy = geo_json.uAxisy;
        if (geo_json.uAxisx == -1) {
            arc_entity.subData().flipHorizontal();
        }
        return arc_entity;
    }
    static toHatch(geometry, properties) {
        var hatch = new ltentity_1.LTHatch();
        //使用cadgeo中的
        this.json2Hatch(geometry, hatch);
        if (hatch.subData()._outline.length == 0) {
            return {};
        }
        this.setProperties(hatch, properties);
        if (properties.info !== undefined && properties.info !== "") {
            let geo_json = JSON.parse(properties.info);
            hatch.setPatternScale(geo_json.patternScale);
            hatch.data.setRotate(geo_json.patternAngle);
            hatch.subData().setClip(geo_json.clip);
            if (geo_json.hidden !== undefined) {
                hatch.visible = false;
            }
        }
        hatch.hatchName = properties.blockname;
        return hatch;
    }
    static drawCenterText(text, text_height, rect) {
        let geo_text = new ltentity_1.LTText(new ltpoint_1.LTPoint(0, 0));
        geo_text.setHeight(text_height);
        geo_text.text = text;
        geo_text.subData().initWH();
        let text_width = geo_text.subData().width;
        let start_text_pos = (rect.getWidth() - text_width) / 2;
        let start_y_pos = (rect.getHeight() - text_height) / 2;
        geo_text.subData().setPosition(new ltpoint_1.LTPoint(rect.getMinimum().x + start_text_pos, rect.getMinimum().y + start_y_pos));
        return geo_text;
    }
    static toSpline(properties) {
        let cad_geo_info = properties.cadgeo;
        let geo_json = JSON.parse(cad_geo_info);
        let points_json = geo_json.ctrlPts;
        if (points_json === undefined) {
            points_json = geo_json.ctrlpts;
        }
        var dwg_points = [];
        //let geo = JSON.parse(points_json);
        for (var m = 0; m < points_json.length; m++) {
            dwg_points.push(new ltpoint_1.LTPoint(Number(points_json[m].x), Number(points_json[m].y)));
        }
        let dwg_entity = new ltentity_1.LTSpline(dwg_points, Number(geo_json.degree));
        this.setProperties(dwg_entity, properties);
        if (geo_json.hidden !== undefined) {
            dwg_entity.visible = false;
        }
        return dwg_entity;
    }
    //将feature 转换为entity 地理坐标转换为像素坐标
    static FeatureToEntity(geojson, feature) {
        let result_entity = null;
        if (feature._properties.entitytype.toLowerCase() == "acdbtext" ||
            feature._properties.entitytype.toLowerCase() == "acdbmtext") {
            let json_info = JSON.parse(feature._properties.cadgeo);
            let pos = this.json2Point(json_info)[0];
            if (pos == undefined) {
                return null;
            }
            var p = new ltpoint_1.LTPoint(pos.x, pos.y);
            result_entity = this.toText(p, feature._properties);
            if (json_info.hidden !== undefined) {
                console.log(feature._properties.text);
                result_entity.visible = false;
            }
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbrotateddimension") {
            result_entity = this.toRotateDimension(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbaligneddimension") {
            result_entity = this.toAlignedDimension(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdb2lineangulardimension") {
            result_entity = this.toAngular2LDimension(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdb3pointangulardimension") {
            result_entity = this.toAngular3PDimension(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbcircle") {
            result_entity = this.toCircle(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbellipse") {
            result_entity = this.toEllipse(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbarc") {
            result_entity = this.toArc(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbline") {
            result_entity = this.toDbLine(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbhatch") {
            let json_info = JSON.parse(feature._properties.cadgeo);
            result_entity = this.toHatch(json_info, feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbsolid") {
            let json_info = JSON.parse(feature._properties.cadgeo);
            result_entity = this.toHatch(json_info, feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbspline") {
            result_entity = this.toSpline(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbpolyline" || feature._properties.entitytype.toLowerCase() == "acdb2dpolyline") {
            result_entity = this.toDbPolyline(feature._properties);
        }
        else if (feature._properties.entitytype.toLowerCase() == "acdbblockreference") {
            result_entity = this.toDbBlockReference(feature._properties);
        }
        else {
            //debugger
        }
        return result_entity;
    }
    static shapeToEntity(shape) {
        if (shape == null) {
            return null;
        }
        else if (shape instanceof segment_1.RCircle) {
            return new ltentity_1.LTCircle(shape);
        }
        else if (shape instanceof segment_1.RLine) {
            return new ltentity_1.LTLine(shape.getStartPoint(), shape.getEndPoint());
        }
        else if (shape instanceof segment_1.RPolyline) {
            return new ltentity_1.LTPolyline(shape);
        }
        else if (shape instanceof segment_1.RText) {
            return new ltentity_1.LTText(shape);
        }
        else if (shape instanceof segment_1.RArc) {
            return new ltentity_1.LTArc(shape);
        }
        else if (shape instanceof segment_1.REllipse) {
            return new ltentity_1.LTEllipse(shape);
        }
        return null;
    }
    //从矩形转多段线;
    static boxToPolyline(box) {
        let points = [];
        points.push(box.getMinimum());
        points.push(new ltpoint_1.LTPoint(box.getMinimum().x + box.getWidth(), box.getMinimum().y));
        points.push(new ltpoint_1.LTPoint(box.getMinimum().x + box.getWidth(), box.getMaximum().y));
        points.push(new ltpoint_1.LTPoint(box.getMinimum().x, box.getMaximum().y));
        points.push(box.getMinimum());
        let polyline = new ltentity_1.LTPolyline(points);
        return polyline;
    }
    static closePoint(p, startPoint, endPoint, is_extended = true) {
        let first_vector = endPoint.minus(startPoint);
        let length = ltpoint_1.LTPoint.getDotProduct(first_vector, first_vector);
        if (length < EPS) {
            return startPoint;
        }
        let second_vector = p.minus(startPoint);
        let param = ltpoint_1.LTPoint.getDotProduct(first_vector, second_vector) / length;
        if (!is_extended && param > 1) {
            return endPoint;
        }
        else if (!is_extended && param < 0) {
            return startPoint;
        }
        return startPoint.plus(first_vector.dot(param));
    }
}
exports.LTUtils = LTUtils;
