"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TsPixiCanvas = exports.LTCanvas = void 0;
const ltentity_1 = require("./ltentity");
const ltpoint_1 = require("./ltpoint");
const rendertext_1 = require("./rendertext");
const segment_1 = require("./segment");
const ltrendermode_1 = require("../ltrendermode");
const TextHeight_Scale = 1.4;
const TextBottom_Scale = 0.3;
var PIXI = window.PIXI;
/**
 * 定义画笔的基类
 *
 */
class LTCanvas {
    constructor(map) {
        this._calcbounds = false;
        this._map = map;
    }
    get map() {
        return this._map;
    }
    get calcBounds() {
        return this._calcbounds;
    }
    set calcBounds(is_calc) {
        this._calcbounds = is_calc;
    }
    getBlock(blockname) { }
    setFill(s) { }
    getBlocks() { }
    /**
     * \brief 绘制线
     * \param points 点集
     */
    drawLineString(points) { }
    /**
     * \brief 绘制两点线
     * \param start_point 开始点 end_point 结束点
     */
    drawLine(start_point, end_point) { }
    /**
     * \brief 绘制面
     * \param points 点集
     */
    drawPolygon(points) { }
    /**
     * \brief 设置颜色
     * \param color 颜色
     */
    setColor(color, layername) { }
    /**
     * \brief 绘制点
     * \param xy 坐标
     */
    drawPoint(x, y) { }
    /**
     * \brief 设置线宽
     * \param width 宽度
     */
    setStrokeWidth(width) { }
    /**
     * \brief canvas save
     * \param
     */
    startClip() { }
    endClip() { }
    save() { }
    /**
     * \brief canvas restore
     * \param
     */
    restore() { }
    /**
     * \brief canvas 放大缩小
     * \param
     */
    scale(x, y) { }
    /**
     * \brief canvas 移动
     * \param
     */
    translate(x, y) { }
    /**
     * \brief 绘制圆
     */
    drawCircle(point, radius) { }
    /**
     * \brief 设置旋转角度
     * \param rotate 角度
     */
    setRotate(rotate) { }
    drawText(ent, text, vmode, hmode, width_factor, ref_width = 0) { }
    drawEllipse() { }
    drawArc(center, radius, start_angle, end_angle, anticlockwise) { }
    drawShape(shapes) { }
    drawBlock(ent, blockname, point, rotate = 0.0, main = true, info = null) {
        return null;
    }
}
exports.LTCanvas = LTCanvas;
class TsPixiCanvas extends LTCanvas {
    constructor(map, only_render = false) {
        super(map);
        this._rotate = 0;
        //正在绘制的列表;
        this._blocksContainers = [];
        this._layername = "0";
        this._mtext = false;
        this._fontname = "";
        this._bold = false;
        this._useTextStyleHeight = false;
        this._clip = false;
        this._fill = false;
        this._is_only_render = false;
        this._clip_graphics = null;
        this._Mat = new PIXI.Matrix();
        this._width = 0.1;
        this._saveMatrix = [];
        this._blocks = new Map();
        this._blocksContainers = [];
        this._is_only_render = false;
    }
    init() {
        this.reset();
        this._blocks = new Map();
        this._blocksContainers = [];
    }
    setFill(s) {
        this._fill = s;
    }
    getBlock(blockname) {
        return this._blocks.get(blockname);
    }
    getBlocks() {
        return this._blocks;
    }
    _newGraphics() {
        return ltrendermode_1.CadSetting._newGraphics();
    }
    setLineStyle(graph) {
        if (this._width == 0) {
            graph.lineStyle({ color: this._color, native: true, width: 1 });
        }
        else {
            graph.lineStyle({ color: this._color, width: this._width, scaleMode: PIXI.smooth.LINE_SCALE_MODE.NORMAL });
        }
        graph.layername = this._layername;
    }
    startDraw(graphics = null) {
        if (graphics == null) {
            alert("graphics is null");
        }
        this._render_graphics = graphics;
        this._graphics = this._newGraphics();
    }
    _appendGraphicsData(ent, graphics) {
        if (graphics.geometry.graphicsData.length == 0) {
            return [];
        }
        let parent_graphics = this._render_graphics;
        let is_block_graphics = false;
        if (this.currentBlock !== null) {
            parent_graphics = this.currentBlock;
            is_block_graphics = true;
        }
        let graphics_data = parent_graphics.geometry.graphicsData;
        let ent_index = 0;
        let ent_data_count = 0;
        //默认仅支持添加;
        let onlyappend = true;
        if (parent_graphics.onlyAppend !== undefined) {
            onlyappend = parent_graphics.onlyAppend;
        }
        if (!onlyappend && !is_block_graphics) {
            //清除旧数据;
            for (let sub_graphics_data of graphics_data) {
                if (sub_graphics_data.entindex === ent) {
                    ent_data_count += 1;
                    continue;
                }
                if (ent_data_count > 0) {
                    break;
                }
                ent_index++;
            }
            if (ent_data_count > 0) {
                graphics_data.splice(ent_index, ent_data_count);
            }
        }
        let data = graphics.geometry.graphicsData;
        if (data.length !== 0) {
            for (let sub_data of data) {
                sub_data.entindex = ent;
            }
            if (ent_data_count > 0) {
                for (let sub_data of data) {
                    graphics_data.splice(ent_index, 0, sub_data);
                    ent_index++;
                }
            }
            else {
                for (var i = 0; i < data.length; i++) {
                    parent_graphics.geometry.graphicsData.push(data[i]);
                }
                // parent_graphics.geometry.graphicsData.push(...data);
            }
        }
        return data;
    }
    endDraw(ent) {
        let data = this._appendGraphicsData(ent, this._graphics);
        if (this.calcBounds) {
            let temp_ent = ent;
            this._graphics.calculateBounds();
            temp_ent._bounds = this._graphics._bounds;
        }
        if (this.currentBlock === null) {
            if (this._clip_graphics !== null) {
                //块裁剪
                if (ent._clip_graphics !== undefined && ent._clip_graphics !== null) {
                    this._render_graphics.removeChild(ent._clip_graphics);
                }
                ent._clip_graphics = this._clip_graphics;
                ent._graphicsData = data;
                this._clip_graphics = null;
            }
            else {
                ent._graphicsData = data;
            }
            if (ent._graphicsData.length == 0) {
                if (ent instanceof ltentity_1.LTEntityPoint) {
                    console.log(`块渲染失败:${ent.subData().blockName}`);
                }
            }
        }
        this._graphics = null;
    }
    reset() {
        //重新初始化
        this._graphics = null;
        this._blocksContainers = [];
        this._rotate = 0;
    }
    _clipblock(info, current_graphics, parent_graphics, offset, rotate) {
        //clip
        let clip = false;
        if (info.filter != undefined && info.filter.enabled == true) {
            let pos = info.filter.pos;
            let clip_grahpics = this._newGraphics();
            clip_grahpics.beginFill("0x00ff00", 1);
            clip = true;
            if (pos.length == 2) {
                clip_grahpics.drawRect(pos[0].x - offset.x, pos[0].y - offset.y, pos[1].x - pos[0].x, pos[1].y - pos[0].y);
            }
            else {
                for (let i = 0; i < pos.length; i++) {
                    let p1 = new ltpoint_1.LTPoint(pos[i].x, pos[i].y);
                    if (rotate !== 0) {
                        p1.rotate(rotate);
                    }
                    let x = Number(p1.x * this._Mat.a + offset.x);
                    let y = Number(p1.y * this._Mat.d + offset.y);
                    if (i == 0) {
                        clip_grahpics.moveTo(x, y);
                    }
                    else {
                        clip_grahpics.lineTo(x, y);
                    }
                }
            }
            clip_grahpics.closePath();
            clip_grahpics.endFill();
            let draw_boundary = info.filter.drawboundary || false;
            if (draw_boundary) {
                let draw_grahpics = this._newGraphics();
                this.setLineStyle(draw_grahpics);
                for (let i = 0; i < pos.size; i++) {
                    let x = Number(pos[i].x * this._Mat.a + offset.x);
                    let y = Number(pos[i].y * this._Mat.d + offset.y);
                    if (i == 0) {
                        draw_grahpics.moveTo(x, y);
                    }
                    else {
                        draw_grahpics.lineTo(x, y);
                    }
                }
                draw_grahpics.closePath();
                parent_graphics.addChild(draw_grahpics);
            }
            current_graphics.mask = clip_grahpics;
            parent_graphics.addChild(current_graphics.mask);
            parent_graphics.addChild(current_graphics);
        }
        return clip;
    }
    _addblock(element, point, rotate, block_graphics, xsize, ysize, sub, info, ent) {
        if (element.ismask === true) {
            return;
        }
        if (element.geometry == null) {
            let epoint = element.point;
            element.children.forEach((e) => {
                if (e.position.x != 0 && e.position.y != 0) {
                    epoint.x = e.position.x;
                    epoint.y = e.position.y;
                }
                let pssds = new ltpoint_1.LTPoint(epoint.x * this._Mat.a + point.x, epoint.y * this._Mat.d + point.y);
                pssds.rotate(rotate, point);
                this._addblock(e, pssds, rotate, block_graphics, e.scale.x, e.scale.y, true, info, ent);
            });
            return false;
        }
        let matrix = new window.PIXI.Matrix();
        matrix.scale(this._Mat.a * xsize, this._Mat.d * ysize);
        let temp_rotate = element.rotation * 1 + rotate * 1;
        matrix.rotate(temp_rotate);
        if (sub == true) {
            matrix.translate(point.x, point.y);
        }
        else {
            matrix.translate(element.position.x + point.x, element.position.y + point.y);
        }
        let element_graphics = block_graphics;
        if (element.mask !== null) {
            element_graphics = this._newGraphics();
        }
        for (let sub_graphics of element.geometry.graphicsData) {
            if (sub_graphics.isExpression !== undefined && sub_graphics.isExpression === true) {
                continue;
            }
            let sub_data = sub_graphics.clone();
            if (sub_graphics.wipeout !== undefined) {
                sub_data.wipeout = sub_graphics.wipeout;
                sub_data.fillStyle.color = this._map.backgroundColor;
            }
            if (sub_data.matrix !== null) {
                let sub_matrix = matrix.clone().append(sub_graphics.matrix);
                sub_data.matrix = sub_matrix;
            }
            else {
                sub_data.matrix = matrix.clone();
            }
            if (sub_data.lineStyle.width > 0) //改宽度
             {
                sub_data.lineStyle = sub_data.lineStyle.clone();
                sub_data.lineStyle.width = sub_data.lineStyle.width * this._Mat.d * ysize;
            }
            //颜色
            if (element.layername !== undefined && element.layername === "0") {
                sub_data.lineStyle.color = ent.entityColor(this.map);
            }
            for (let sub_hole of sub_graphics.holes) {
                let temp_hole = sub_hole.clone();
                if (temp_hole.matrix !== null) {
                    temp_hole.matrix = matrix.clone().append(sub_hole.matrix);
                }
                else {
                    temp_hole.matrix = matrix.clone();
                }
                sub_data.holes.push(temp_hole);
            }
            element_graphics.geometry.graphicsData.push(sub_data);
        }
        if (element.expression !== undefined && element.expression != null && element.expression.length > 0) {
            for (let exp of element.expression) {
                if (info[exp.expression] == undefined || info[exp.expression] == null) {
                    continue;
                }
                info[exp.expression].draw = true;
                let t = info[exp.expression].text;
                let render_text = new rendertext_1.RenderText("汉仪中等线", t, exp.height, exp.width_factor, exp.ref_width);
                let graphics = render_text.getGraphics(this._color, exp.vmode, exp.hmode, exp.matrixInfo, matrix);
                for (var i = 0; i < graphics.geometry.graphicsData.length; i++) {
                    let data = graphics.geometry.graphicsData[i];
                    data.istext = true;
                    element_graphics.geometry.graphicsData.push(data);
                }
                // block_graphics.geometry.graphicsData.push(...graphics.geometry.graphicsData);
            }
        }
        element.children.forEach((e) => {
            this._addblock(e, point, rotate, element_graphics, xsize, ysize, sub, info, ent);
        });
        if (element.mask !== null) {
            if (element_graphics.mask == null) {
                element_graphics.mask = this._newGraphics();
                element_graphics.mask.ismask = true;
            }
            for (let mask_sub_graphics of element.mask.geometry.graphicsData) {
                let mask_sub_data = mask_sub_graphics.clone();
                if (mask_sub_data.matrix !== null) {
                    let sub_matrix = matrix.clone().append(mask_sub_graphics.matrix);
                    mask_sub_data.matrix = sub_matrix;
                }
                else {
                    mask_sub_data.matrix = matrix.clone();
                }
                //block_graphics.geometry.graphicsData.push(mask_sub_data);
                element_graphics.mask.geometry.graphicsData.push(mask_sub_data);
            }
            element_graphics.addChild(element_graphics.mask);
            block_graphics.addChild(element_graphics);
        }
    }
    drawBlock(ent, blockname, point, rotate, main, info) {
        let blocks = this._blocks.get(blockname);
        if (blocks == null) {
            let blocksContainer = this._newGraphics();
            this._blocksContainers.push({
                _block: blockname,
                _container: [blocksContainer],
            });
            return null;
        }
        let block_graphics = this._newGraphics();
        let mask = false;
        blocks.forEach((element) => {
            this._addblock(element, point, rotate, block_graphics, 1, 1, false, info, ent);
            if (element.mask !== null) {
                mask = true;
            }
        });
        //绘制属性
        if (info.A !== undefined && info.A.draw !== true && info.attribute !== undefined) {
            let t = info.A.text;
            let render_text = new rendertext_1.RenderText("汉仪中等线", info.attribute.text, info.attribute.height, 1, 0);
            let graphics = render_text.getGraphics(this._color, info.A.vmode, info.A.hmode, { isexpression: false, rotate: info.attribute.getRotate(),
                x: info.attribute.position.x, y: info.attribute.position.y }, null);
            for (var i = 0; i < graphics.geometry.graphicsData.length; i++) {
                let data = graphics.geometry.graphicsData[i];
                data.istext = true;
                block_graphics.geometry.graphicsData.push(data);
            }
            //block_graphics.geometry.graphicsData.push(...graphics.geometry.graphicsData);
        }
        let cliped = false;
        //内部块
        let container;
        if (info.filter != undefined && info.filter.enabled == true) {
            cliped = true;
            container = this._newGraphics();
            this._clipblock(info, block_graphics, container, point, rotate);
            //container.addChild(block_graphics);
        }
        else if (mask) {
            cliped = true;
            container = block_graphics;
        }
        else {
            //自定义的属性;
            block_graphics.point = point;
            block_graphics.xsize = this._Mat.a;
            block_graphics.ysize = this._Mat.d;
        }
        if (main == true) {
            //添加到渲染;
            let line = this._graphics;
            if (this.currentBlock != null) {
                line = this.currentBlock;
            }
            if (cliped == false) {
                for (var i = 0; i < block_graphics.geometry.graphicsData.length; i++) {
                    line.geometry.graphicsData.push(block_graphics.geometry.graphicsData[i]);
                }
            }
            else {
                if (container !== undefined) {
                    //container 这个保存到ent中
                    this._render_graphics.addChild(container);
                    this._clip_graphics = container;
                }
            }
        }
        else {
            //上一个是否为空
            if (this.currentBlock !== null) {
                if (this.currentBlock.geometry.graphicsData.length == 0 && this.currentBlock.children.length == 0) {
                    this._blocksContainers[this._blocksContainers.length - 1]._container.pop();
                }
            }
            block_graphics;
            block_graphics.blockname = blockname;
            this._blocksContainers[this._blocksContainers.length - 1]._container.push(block_graphics);
            //下一个重新创建
            let blocksContainer = this._newGraphics();
            this._blocksContainers[this._blocksContainers.length - 1]._container.push(blocksContainer);
        }
    }
    get currentBlock() {
        if (this._blocksContainers.length > 0) {
            let blocks = this._blocksContainers[this._blocksContainers.length - 1]._container;
            return blocks[blocks.length - 1];
        }
        return null;
    }
    endBlock() {
        let block = this._blocksContainers.pop();
        //获取所有的graphics;
        if (block == undefined) {
            return;
        }
        this._blocks.set(block._block, block._container);
        return block._container;
    }
    drawLineString(points) {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        if (line === null) {
            return;
        }
        this.setLineStyle(line);
        for (var i = 0; i < points.length; ++i) {
            //将点坐标转成wkt格式;
            var point = points[i];
            if (i == 0) {
                line.moveTo(point.x, point.y);
            }
            else {
                line.lineTo(point.x, point.y);
            }
        }
        line.endFill();
    }
    startClip() {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        line.beginHole();
        this._clip = true;
    }
    endClip() {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        line.closePath();
        line.endFill();
        this._clip = false;
    }
    drawLine(start_point, end_point) {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        this.setLineStyle(line);
        line.moveTo(start_point.x, start_point.y);
        line.lineTo(end_point.x, end_point.y);
        line.endFill();
    }
    drawPolygon(points) {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        line.beginFill(this._color);
        for (var i = 0; i < points.length; ++i) {
            //将点坐标转成wkt格式;
            var point = points[i];
            if (i == 0) {
                line.moveTo(point.x, point.y);
            }
            else {
                line.lineTo(point.x, point.y);
            }
        }
        if (this._clip == false) {
            line.endFill();
        }
        line.layername = this._layername;
    }
    setColor(color, layername) {
        if (color != "" && this._color !== color) {
            this._color = color;
        }
        this._layername = layername;
    }
    drawPoint(x, y) {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        line.lineStyle({ color: this._color, native: true, width: 1 });
        line.drawCircle(x, y, 1);
        line.layername = this._layername;
        line.endFill();
    }
    setStrokeWidth(width) {
        this._width = width;
    }
    save() {
        this._saveMatrix.push(this._Mat);
        this._Mat = this._Mat.clone();
    }
    restore() {
        this._width = 0;
        this._Mat = this._saveMatrix.pop();
    }
    scale(x, y) {
        this._Mat.scale(x, y);
    }
    translate(x, y) {
        this._Mat.translate(x, y);
    }
    static getTextWH(text, h, width_factor, ref_width) {
        let text_height = h * 1.298;
        let render_text = new rendertext_1.RenderText("汉仪中等线", text, text_height, width_factor, ref_width);
        return render_text.getGraphicsWH('0xff0000');
    }
    drawText(ent, text, vmode, hmode, width_factor, ref_width = 0) {
        //text = "1㎡";
        //测试得出待验证 1.298
        if (width_factor === undefined || width_factor === null || isNaN(width_factor)) {
            width_factor = 1;
        }
        if (ent instanceof ltentity_1.LTText) {
            this._fontname = ent.fontName;
        }
        let text_style = this._map.dwgservice.getTextStyle(this._fontname);
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        let text_height = this._width * 1.298;
        let isexpression = false;
        if (ent instanceof ltentity_1.LTText) {
            if (ent.subData()._expression == true) {
                if (line.expression == undefined) {
                    line.expression = new Array;
                }
                line.expression.push({ isexpression: true, ref_width: ref_width, expression: text, vmode: vmode, hmode: hmode, matrixInfo: { rotate: this._rotate, x: this._Mat.tx, y: this._Mat.ty }, height: text_height });
                isexpression = true;
            }
        }
        if (this._useTextStyleHeight && text_style !== null && text_style.textSize > 0) {
            text_height = text_style.textSize;
        }
        if (text_style == null || !text_style.valid) {
            let render_text = new rendertext_1.RenderText("汉仪中等线", text, text_height, width_factor, ref_width);
            let graphics = render_text.getGraphics(this._color, vmode, hmode, { isexpression: isexpression, rotate: this._rotate, x: this._Mat.tx, y: this._Mat.ty, width_factor: width_factor }, null);
            line.geometry.graphicsData.push(...graphics.geometry.graphicsData);
            this._rotate = 0;
            return;
        }
        else {
            let graphics = text_style.graphicsData(text, this._width, this._color, vmode, hmode, { isexpression: isexpression, rotate: this._rotate, x: this._Mat.tx, y: this._Mat.ty, width_factor: width_factor });
            line.geometry.graphicsData.push(...graphics.geometry.graphicsData);
            this._rotate = 0;
            return;
        }
    }
    drawTextInPosition(text, center, position, rotation) {
    }
    drawCircle(point, radius) {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        if (this._fill) {
            line.beginFill(this._color);
        }
        else {
            this.setLineStyle(line);
        }
        line.drawCircle(point.x, point.y, radius);
        line.endFill();
    }
    drawArc(center, radius, start_angle, end_angle, anticlockwise) {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        this.setLineStyle(line);
        line.arc(center.x, center.y, radius, start_angle, end_angle, anticlockwise);
        line.endFill();
    }
    setRotate(rotate) {
        this._rotate = rotate;
    }
    setFont(fontname, bold, mode, mtext, useTextStyleHeight) {
        this._fontname = fontname;
        this._bold = bold;
        this._mtext = mtext;
        this._useTextStyleHeight = useTextStyleHeight;
    }
    drawShape(shapes) {
        let line = this._graphics;
        if (this.currentBlock != null) {
            line = this.currentBlock;
        }
        if (shapes.length == 1) {
            if (shapes[0] instanceof segment_1.RPolyline && window.cadmap && shapes[0].isWipeOut) {
                //绘制背景色，用于区域覆盖;
                line.beginFill(window.cadmap.backgroundColor, 1);
                let points = shapes[0].vertices;
                for (var i = 0; i < points.length; ++i) {
                    //将点坐标转成wkt格式;
                    var point = points[i];
                    if (i == 0) {
                        line.moveTo(point.x, point.y);
                    }
                    else {
                        line.lineTo(point.x, point.y);
                    }
                }
                line.endFill();
                line.geometry.graphicsData[line.geometry.graphicsData.length - 1].wipeout = true;
                return;
            }
        }
        if (this._fill) {
            line.beginFill(this._color);
        }
        else {
            line.lineStyle({ color: this._color, native: true, width: 1 });
        }
        let trans = [];
        for (let i = 0; i < shapes.length; ++i) {
            let shape = shapes[i];
            if (shape instanceof segment_1.RLine) {
                let start_point = shape.getStartPoint();
                let end_point = shape.getEndPoint();
                line.moveTo(start_point.x, start_point.y);
                line.lineTo(end_point.x, end_point.y);
            }
            else if (shape instanceof segment_1.RArc) {
                line.arc(shape.getCenter().x, shape.getCenter().y, shape.getRadius(), shape.getStartAngle(), shape.getEndAngle(), shape.isReversed);
            }
            else if (shape instanceof segment_1.RTriangle) {
                trans.push(shape);
            }
        }
        line.endFill();
        //对于宽度不同的线的解决办法;
        if (trans.length > 0) {
            line.beginFill(this._color);
            for (let sub_shape of trans) {
                let sub_shape1 = sub_shape;
                let points = sub_shape1.points;
                line.moveTo(points[0].x, points[0].y);
                line.lineTo(points[1].x, points[1].y);
                line.lineTo(points[2].x, points[2].y);
                line.lineTo(points[0].x, points[0].y);
            }
            line.endFill();
        }
    }
}
exports.TsPixiCanvas = TsPixiCanvas;
