"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LTDimension = exports.LTHatch = exports.LTText = exports.LTBlockReference = exports.LTSpline = exports.LTEllipse = exports.LTArc = exports.LTPolyline = exports.LTLine = exports.LTCircle = exports.LTEntityPoint = exports.LTEntity = exports.dwg_bylayer = exports.linetype_continuous = void 0;
const ltpoint_1 = require("./ltpoint");
const segment_js_1 = require("./segment.js");
const ltfields_1 = require("../data/ltfields");
const exporter_1 = require("./exporter");
const rspline_1 = require("./rspline");
const dimension_1 = require("./dimension");
const blocksbush_1 = require("./blocksbush");
exports.linetype_continuous = "continuous";
exports.dwg_bylayer = "bylayer";
/**
 * LTEntity 实体基类
 */
class LTEntity {
    constructor() {
        this._layer = "0";
        this._colorInfo = { color: exports.dwg_bylayer, aci: false, index: "-1" };
        this._linetype = exports.linetype_continuous;
        this._linetypescale = 1;
        this._linewidth = exports.dwg_bylayer;
        this._visible = true;
        this._layer;
        this._isSub = false;
        this._entity_data = {};
        this._graphicsData = [];
    }
    get visible() {
        return this._visible;
    }
    set visible(value) {
        this._visible = value;
    }
    _drawBlock(ent, blockname, canvas, info, graphics) {
        let block = canvas.map.dwgservice
            .getBlock(blockname);
        if (block != null) {
            canvas.save();
            if (canvas.getBlock(blockname) == null) {
                var sub_data = this._entity_data;
                if (sub_data.position == null) {
                    return;
                }
                canvas.drawBlock(ent, blockname, sub_data.position);
                block.setLayerName(this._layer);
                block.worldDraw(canvas, false, info, graphics);
                canvas.endBlock();
            }
            canvas.restore();
            return true;
        }
        //不存在块型
        return false;
    }
    //返回其相关的真实的数据存储类;
    get data() {
        return this._entity_data;
    }
    set data(data) {
        if (data == null) {
            return;
        }
        this._entity_data = data;
    }
    /**
     * \brief 设置线型比例尺
     */
    setLineTypeScale(scale) {
        this._linetypescale = scale;
    }
    getLineTypeScale() {
        return this._linetypescale;
    }
    setColorInfo(value) {
        if (value === undefined) {
            return;
        }
        this._colorInfo = JSON.parse(value);
    }
    getColorInfo() {
        return JSON.stringify(this._colorInfo);
    }
    /**
     * \brief 设置颜色 直接是颜色
     * \param value 颜色
     */
    setColor(value) {
        if (value === undefined) {
            return;
        }
        this._colorInfo.index = "-1";
        this._colorInfo.aci = false;
        this._colorInfo.color = value;
    }
    getColor() {
        return this._colorInfo.color;
    }
    /**
     * \brief 获取颜色
     */
    color() {
        return this._colorInfo.color;
    }
    //获取实体颜色
    entityColor(map) {
        if (this._colorInfo.color == "" || this._colorInfo.color.toLowerCase() == exports.dwg_bylayer) {
            let color = map.dwgservice.getLayerColor(this._layer);
            return color;
        }
        return this._colorInfo.color;
    }
    entityWidth() {
        let line_width = this._linewidth;
        if (line_width == undefined) {
            return 0;
        }
        if (typeof line_width == "string" && (line_width == "" || line_width.toLowerCase() == exports.dwg_bylayer)) {
            return 0;
        }
        return this._linewidth * 1.0;
    }
    entityScale() {
        let line_scale = this._linetypescale;
        if (typeof line_scale == "string" && (line_scale == "" || line_scale.toLowerCase() == exports.dwg_bylayer)) {
            return 1.0;
        }
        return this._linetypescale * 1.0;
    }
    setStrokeWidth(stroke_width) {
        this._linewidth = stroke_width;
    }
    /**
     * \brief 设置图层名
     * \param name 图层名
     */
    setLayerName(name) {
        this._layer = name;
    }
    /**
     * \brief 获取图层名
     */
    getLayerName() {
        return this._layer;
    }
    /**
     * \brief 设置旋转角度
     */
    setRotate(rotate) {
        if (this._entity_data != null) {
            this._entity_data.setRotate(rotate);
        }
    }
    /**
     * \brief 绘制入口 子类中重写
     * \param main  画笔
     */
    worldDraw(canvas, main, info, graphics) {
    }
    startDraw(canvas, main, graphics) {
        if (main == true) {
            canvas.startDraw(graphics);
        }
    }
    endDraw(canvas, main) {
        if (main == true) {
            let g = canvas.endDraw(this);
            canvas.reset();
        }
    }
    /**
     * \brief 设置子实体
     */
    setIsSub(is_sub) {
        this._isSub = is_sub;
    }
    /**
     * \brief 设置线型
     */
    setLineType(linetype) {
        this._linetype = linetype;
    }
    getLineType() {
        return this._linetype;
    }
    /**
     * \brief 设置子实体
     */
    isSub() {
        if (typeof this._isSub == "undefined") {
            return false;
        }
        return this._isSub;
    }
    //shape对象转entity
    static toEntity(shape) {
        // if (shape instanceof RPolyline) {
        //   var result = new LTPolyline(shape);
        // }
    }
    getClosestPointOnEntity(point, rangle, limited) {
        limited = limited || true;
        if (this._entity_data == null) {
            return null;
        }
        return this._entity_data.getClosestPointOnEntity(point, rangle, limited);
    }
    getEndPoints(query_box) {
        if (this._entity_data == null) {
            return [];
        }
        return this._entity_data.getEndPointsByBox(query_box);
    }
    getIntersectionPoints(other, limited, query_box) {
        if (this._entity_data == null) {
            return [];
        }
        if (other == this) {
            return [];
        }
        return this._entity_data.getIntersectionPointsByBox(other.data, limited, false, query_box);
    }
    getMiddlePoints(query_box) {
        if (this._entity_data == null) {
            return [];
        }
        return this._entity_data.getMiddlePointsByBox(query_box);
    }
    getCenterPoints(query_box) {
        if (this._entity_data == null) {
            return [];
        }
        return this._entity_data.getCenterPointsByBox(query_box);
    }
    moveReferencePoint(referencePoint, targetPoint) {
        if (this._entity_data == null) {
            return false;
        }
        return this._entity_data.moveReferencePoint(referencePoint, targetPoint);
    }
    getReferencePoints() {
        if (this._entity_data == null) {
            return false;
        }
        return this._entity_data.getReferencePoints();
    }
    cloneTo(entity) {
        entity._colorInfo = Object.assign({}, this._colorInfo);
        entity._layer = this._layer;
        entity._linetype = this._linetype;
        entity._linewidth = this._linewidth;
        if (entity._entity_data instanceof segment_js_1.Shape) {
            entity._entity_data.setRotate(this._entity_data.getRotate());
        }
        entity._linetypescale = this._linetypescale;
    }
    cloneFrom(entity) {
        this._colorInfo = entity._colorInfo;
        this._layer = entity._layer;
        this._linetype = entity._linetype;
        this._linewidth = entity._linewidth;
        this._entity_data = entity._entity_data.clone();
        if (entity._entity_data instanceof segment_js_1.Shape) {
            this._entity_data.setRotate(entity._entity_data.getRotate());
        }
    }
    getBoundingBox() {
        if (this._entity_data == null) {
            return ltpoint_1.RBox.nullBox();
        }
        return this._entity_data.getBoundingBox();
    }
    intersectsWith(shape) {
        if (this._entity_data == null) {
            return false;
        }
        return this._entity_data.intersectsWith(shape._entity_data, true);
    }
    move(offset) {
        if (this._entity_data == null) {
            return false;
        }
        return this._entity_data.move(offset);
    }
    rotate(rotation, center) {
        if (this._entity_data == null) {
            return false;
        }
        return this._entity_data.rotate(rotation, center);
    }
    scale(factors, center) {
        if (this._entity_data == null) {
            return false;
        }
        return this._entity_data.scale(factors, center);
    }
    geometryToWkt() {
        return this._entity_data.toWkt();
    }
    getSaveValue() {
        return this._entity_data.getSaveValue();
    }
}
exports.LTEntity = LTEntity;
/**
 * LTEntityPoint 点实体
 */
class LTEntityPoint extends LTEntity {
    constructor(point) {
        super();
        this._entity_graphics = [];
        if (point instanceof ltpoint_1.LTPoint) {
            this._entity_data = new segment_js_1.RBlockReference(point);
        }
        else {
            this._entity_data = new segment_js_1.RBlockReference(new ltpoint_1.LTPoint(0, 0));
        }
    }
    get [Symbol.toStringTag]() {
        return 'LTEntityPoint';
    }
    clone() {
        let point = new LTEntityPoint();
        point._entity_data = this._entity_data.clone();
        super.cloneTo(point);
        return point;
    }
    cloneFrom(entity) {
        super.cloneFrom(entity);
        if (entity instanceof LTEntityPoint) {
            this.subData()._blockInfo = entity.subData()._blockInfo;
            let att = entity.subData()._attribute;
            if (att !== null) {
                this.subData()._attribute = att.clone();
            }
        }
    }
    setPoint(point) {
        var sub_data = this._entity_data;
        sub_data.setPoint(point);
    }
    /**
     * \brief 设置块名
     */
    set blockName(blockname) {
        this.subData().blockName = blockname;
    }
    get blockName() {
        return this.subData().blockName;
    }
    /**
     * \brief 设置块信息 xsize ysize
     */
    setBlockInfo(blockInfo) {
        this.subData().setBlockInfo(blockInfo);
    }
    getBoundingBox() {
        if (this._entity_data == null) {
            return ltpoint_1.RBox.nullBox();
        }
        return this._entity_data.getBoundingBox();
    }
    subData() {
        return this._entity_data;
    }
    scale(factors, center) {
        if (this._entity_data == null) {
            return;
        }
        this._entity_data.scale(factors, center);
        return;
    }
    /**
     * \brief 绘制入口
     */
    worldDraw(canvas, main, info, graphics) {
        //获取块信息
        if (!this.visible) {
            return;
        }
        this.subData().render(canvas, this, main, info, graphics);
    }
    getSaveValue() {
        let dict = [];
        dict[ltfields_1.FieldNames.BlockName] = this.subData().blockName;
        let blockinfo = this.subData()._blockInfo;
        blockinfo.xsize = this.subData().scaleFactors.x;
        blockinfo.ysize = this.subData().scaleFactors.y;
        blockinfo.columnCount = this.subData().columnCount;
        blockinfo.rowCount = this.subData().rowCount;
        blockinfo.columnSpacing = this.subData().columnSpacing;
        blockinfo.rowSpacing = this.subData().rowSpacing;
        //属性
        if (this.subData()._attribute !== null) {
            let att = this.subData()._attribute;
            blockinfo["A"] = { alignmentx: att === null || att === void 0 ? void 0 : att.position.x, alignmenty: att === null || att === void 0 ? void 0 : att.position.y,
                hmode: att === null || att === void 0 ? void 0 : att.textHorzMode, vmode: att === null || att === void 0 ? void 0 : att.textVertMode, text: att === null || att === void 0 ? void 0 : att.text, height: att === null || att === void 0 ? void 0 : att.height, rotate: att === null || att === void 0 ? void 0 : att.getRotate() };
        }
        let str = JSON.stringify(blockinfo);
        dict[ltfields_1.FieldNames.Info] = str;
        dict[ltfields_1.FieldNames.Rotate] = this.subData().getRotate();
        let geo = [{ x: this.subData().position.x, y: this.subData().position.y }];
        str = JSON.stringify(geo);
        dict[ltfields_1.FieldNames.Cadgeo] = str;
        return dict;
    }
}
exports.LTEntityPoint = LTEntityPoint;
/**
 * LTCircle 圆实体
 */
class LTCircle extends LTEntity {
    constructor(center, radius) {
        super();
        if (center instanceof ltpoint_1.LTPoint) {
            if (typeof radius === 'number') {
                this._entity_data = new segment_js_1.RCircle(center, radius);
            }
        }
        else {
            this._entity_data = center;
        }
        this._linewidth = 1.0;
        this._linetypescale = 1;
    }
    subData() {
        return this._entity_data;
    }
    get [Symbol.toStringTag]() {
        return 'LTCircle';
    }
    worldDraw(canvas, main, info, graphics) {
        //设置线宽与颜色
        if (!this.visible) {
            return;
        }
        this.startDraw(canvas, main, graphics);
        var linttype_partten = null;
        if (this._linetype.toLowerCase() == exports.dwg_bylayer) {
            linttype_partten = canvas.map.dwgservice
                .getLayerLineType(this.getLayerName());
        }
        else if (this._linetype.toLowerCase() !== exports.linetype_continuous) {
            linttype_partten = canvas.map.dwgservice
                .getLineType(this._linetype);
        }
        canvas.setStrokeWidth(this.entityWidth());
        canvas.setColor(this.entityColor(canvas.map), this.getLayerName());
        //判断是否有线型的情况
        if (linttype_partten != null) {
            let porter = new exporter_1.RPainterPathExporter();
            if (!porter.exportEntity(canvas, this, linttype_partten, 0, graphics)) {
                canvas.drawCircle(this.subData().getCenter(), this.subData().getRadius());
            }
        }
        else {
            canvas.drawCircle(this.subData().getCenter(), this.subData().getRadius());
        }
        this.endDraw(canvas, main);
    }
    /**
     * \brief 增加点
     */
    setRadius(r) {
        this.subData().setRadius(r);
    }
    clone() {
        let circle = new LTCircle(this.subData().getCenter().clone(), this.subData().getRadius());
        super.cloneTo(circle);
        return circle;
    }
    getSaveValue() {
        let dict = this.subData().getSaveValue();
        dict[ltfields_1.FieldNames.Width] = this._linewidth;
        const json = { "linetypesize": this._linetypescale };
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Info] = str;
        return dict;
    }
}
exports.LTCircle = LTCircle;
/**
 * LTLine 线段
 */
class LTLine extends LTEntity {
    constructor(start, end) {
        super();
        this._dis = null;
        this._entity_data = new segment_js_1.RLine(start, end);
    }
    clone() {
        let sub_line = this._entity_data.clone();
        let result = new LTLine(sub_line.getStartPoint(), sub_line.getEndPoint());
        super.cloneTo(result);
        return result;
    }
    get [Symbol.toStringTag]() {
        return 'LTLine';
    }
    subData() {
        return this._entity_data;
    }
    setStart(start) {
        this.subData().setStartPoint(start);
    }
    setEnd(end) {
        this.subData().setEndPoint(end);
    }
    getStart() {
        return this.subData().getStartPoint();
    }
    getEnd() {
        return this.subData().getEndPoint();
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        this.startDraw(canvas, main, graphics);
        var pos = [];
        pos.push(this.getStart());
        pos.push(this.getEnd());
        //设置线宽与颜色
        canvas.setStrokeWidth(this.entityWidth());
        canvas.setColor(this.entityColor(canvas.map), this.getLayerName());
        var linetype = this._linetype;
        var linttype_partten = null;
        if (this._linetype.toLowerCase() == exports.dwg_bylayer) {
            linttype_partten = canvas.map.dwgservice
                .getLayerLineType(this.getLayerName());
        }
        else if (linetype != exports.linetype_continuous) {
            linttype_partten = canvas.map.dwgservice
                .getLineType(linetype);
        }
        //判断是否有线型的情况
        if (linttype_partten != null) {
            canvas.save();
            linttype_partten.setSacle(canvas.map.dwgservice
                .getLtScale() * this.entityScale());
            //let porter=new RShapesExporter(canvas,this,ltlinetype);
            //  porter.exportPolyline(this.subData());
            let porter = new exporter_1.RPainterPathExporter();
            porter.exportEntity(canvas, this, linttype_partten, 0, graphics);
            canvas.restore();
            this.endDraw(canvas, main);
            return;
        }
        //绘制普通直线
        canvas.drawLineString(pos);
        this.endDraw(canvas, main);
    }
    /**
     * \brief 获取线段的长度
     */
    getLength() {
        if (this._dis === null) {
            this._dis = Math.sqrt((this.getEnd().x - this.getStart().x) *
                (this.getEnd().x - this.getStart().x) +
                (this.getEnd().y - this.getStart().y) *
                    (this.getEnd().y - this.getStart().y));
            return this._dis;
        }
        return 0;
    }
    getSaveValue() {
        let dict = this.subData().getSaveValue();
        dict[ltfields_1.FieldNames.Width] = this._linewidth;
        const json = { "linetypesize": this._linetypescale };
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Info] = str;
        return dict;
    }
}
exports.LTLine = LTLine;
;
/**
 * LTPolyline 线实体
 */
class LTPolyline extends LTEntity {
    constructor(points) {
        super();
        if (points instanceof segment_js_1.Shape) {
            this._entity_data = points;
        }
        else if (typeof points === "undefined") {
            var temp_points = [];
            this._entity_data = new segment_js_1.RPolyline(temp_points);
        }
        else {
            if (points.length != 0) {
                this._entity_data = new segment_js_1.RPolyline(points);
            }
        }
        this._linewidth = 0;
        this._linetypescale = 1;
    }
    get [Symbol.toStringTag]() {
        return 'LTPolyline';
    }
    clone() {
        let line = new LTPolyline(this._entity_data.clone());
        super.cloneTo(line);
        return line;
    }
    subData() {
        return this._entity_data;
    }
    /**
     * \brief 设置线型
     */
    setLineType(linetype) {
        this._linetype = linetype;
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        this.startDraw(canvas, main, graphics);
        var pos = this.subData().vertices;
        //设置线宽与颜色
        canvas.setStrokeWidth(this.entityWidth());
        if (this.subData().isWipeOut) {
            canvas.save();
            canvas.drawShape([this.subData()]);
            canvas.restore();
            this.endDraw(canvas, main);
            return;
        }
        else {
            canvas.setColor(this.entityColor(canvas.map), this.getLayerName());
        }
        var linetype = this._linetype;
        var linttype_partten = null;
        if (this._linetype == undefined || this._linetype == null) {
            return;
        }
        else if (this._linetype.toLowerCase() == exports.dwg_bylayer) {
            linttype_partten = canvas.map.dwgservice
                .getLayerLineType(this.getLayerName());
        }
        else if (linetype != exports.linetype_continuous) {
            linttype_partten = canvas.map.dwgservice
                .getLineType(linetype);
        }
        //判断是否有线型的情况
        if (linttype_partten != null) {
            canvas.save();
            linttype_partten.setSacle(canvas.map.dwgservice
                .getLtScale() * this.entityScale());
            //let porter=new RShapesExporter(canvas,this,ltlinetype);
            //  porter.exportPolyline(this.subData());
            let porter = new exporter_1.RPainterPathExporter();
            if (porter.exportEntity(canvas, this, linttype_partten, 0, graphics) == false) {
                canvas.drawLineString(pos);
            }
            canvas.restore();
            this.endDraw(canvas, main);
            return;
        }
        if (this.subData().hasArcSegments() || this.subData().hasWidths()) {
            let sub_shapes = this.subData().getShapes2();
            canvas.drawShape(sub_shapes);
        }
        else {
            //绘制普通直线
            canvas.drawLineString(pos);
        }
        this.endDraw(canvas, main);
    }
    /**
     * \brief 增加点
     */
    addPoint(point) {
        this.subData().appendVertex(point);
    }
    //获取所有点
    getPoints() {
        return this.subData().vertices;
    }
    getSaveValue() {
        let dict = this.subData().getSaveValue();
        dict[ltfields_1.FieldNames.Width] = this._linewidth;
        const json = { "linetypesize": this._linetypescale };
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Info] = str;
        return dict;
    }
}
exports.LTPolyline = LTPolyline;
class LTArc extends LTEntity {
    constructor(center, radius = 0, start_angle = 0, end_angle = 0, reversed = false) {
        super();
        if (center instanceof segment_js_1.RArc) {
            this._entity_data = center;
        }
        else {
            this._entity_data = new segment_js_1.RArc(center, radius, start_angle, end_angle, reversed);
        }
    }
    get [Symbol.toStringTag]() {
        return 'LTArc';
    }
    static fromShape(shape) {
        let arc = new LTArc(shape);
        return arc;
    }
    subData() {
        return this._entity_data;
    }
    clone() {
        let arc = new LTArc(this.data.clone());
        super.cloneTo(arc);
        return arc;
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        //设置线宽与颜色
        this.startDraw(canvas, main, graphics);
        canvas.setStrokeWidth(this.entityWidth());
        canvas.setColor(this.entityColor(canvas.map), this.getLayerName());
        var en_data = this.subData();
        canvas.drawArc(en_data.getCenter(), en_data.getRadius(), en_data.getStartAngle(), en_data.getEndAngle(), en_data.isReversed);
        this.endDraw(canvas, main);
    }
    getSaveValue() {
        let dict = this.subData().getSaveValue();
        dict[ltfields_1.FieldNames.Width] = this._linewidth;
        const json = { "linetypesize": this._linetypescale };
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Info] = str;
        return dict;
    }
}
exports.LTArc = LTArc;
class LTEllipse extends LTEntity {
    constructor(center, majorPoint, ratio, startParam, endParam, reversed) {
        super();
        this._linetypescale = 1;
        if (center instanceof segment_js_1.REllipse) {
            this._entity_data = center;
        }
        else {
            if (majorPoint instanceof ltpoint_1.LTPoint && typeof ratio === "number"
                && typeof startParam === "number" && typeof endParam === "number" && typeof reversed === "boolean")
                this._entity_data = new segment_js_1.REllipse(center, majorPoint, ratio, startParam, endParam, reversed);
        }
    }
    get [Symbol.toStringTag]() {
        return 'LTEllipse';
    }
    clone() {
        let eli = new LTEllipse(this.subData().clone());
        super.cloneTo(eli);
        return eli;
    }
    subData() {
        return this._entity_data;
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        //设置线宽与颜色
        this.startDraw(canvas, main, graphics);
        canvas.setColor(this.entityColor(canvas.map), this.getLayerName());
        var en_data = this.subData();
        var linttype_partten = null;
        if (this._linetype.toLowerCase() == exports.dwg_bylayer) {
            linttype_partten = canvas.map.dwgservice
                .getLayerLineType(this.getLayerName());
        }
        else {
            linttype_partten = canvas.map.dwgservice
                .getLineType(this._linetype);
        }
        let porter = new exporter_1.RPainterPathExporter();
        porter.exportEntity(canvas, this, linttype_partten, 0, graphics);
        this.endDraw(canvas, main);
    }
    getSaveValue() {
        let dict = this.subData().getSaveValue();
        dict[ltfields_1.FieldNames.Width] = this._linewidth;
        const json = { "linetypesize": this._linetypescale };
        let str = JSON.stringify(json);
        dict[ltfields_1.FieldNames.Info] = str;
        return dict;
    }
}
exports.LTEllipse = LTEllipse;
//样条曲线;
class LTSpline extends LTEntity {
    constructor(controls_points, degree = 3) {
        super();
        if (controls_points instanceof rspline_1.RSpline) {
            this._entity_data = controls_points;
        }
        else {
            this._entity_data = new rspline_1.RSpline(controls_points, degree);
        }
    }
    get [Symbol.toStringTag]() {
        return 'LTSpline';
    }
    static fromShape(shape) {
        let spline = new LTSpline(shape);
        return spline;
    }
    subData() {
        return this._entity_data;
    }
    clone() {
        let arc = new LTSpline(this.data.clone());
        super.cloneTo(arc);
        return arc;
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        var pos_list = this.subData().toPolyline().vertices;
        this.startDraw(canvas, main, graphics);
        //设置线宽与颜色
        canvas.setStrokeWidth(this.entityWidth());
        canvas.setColor(this.entityColor(canvas.map), this.getLayerName());
        canvas.drawLineString(pos_list);
        this.endDraw(canvas, main);
    }
}
exports.LTSpline = LTSpline;
/**
 * LTBlockReference 块实体
 */
class LTBlockReference extends LTEntity {
    constructor() {
        super();
        this._layername = "";
        this._isupdate = false;
        this._entitys = [];
        this._bush = new blocksbush_1.BlockBush(this);
    }
    get [Symbol.toStringTag]() {
        return 'LTBlockReference';
    }
    /**
     * \brief 增加子实体
     */
    addEntity(entity) {
        this._entitys.push(entity);
    }
    updateSpatialIndex() {
        this._bush.updateBush();
    }
    spatialIndex() {
        return this._bush;
    }
    unshift(entity) {
        this._entitys.unshift(entity);
    }
    scale(scale, pos) {
        for (var i = 0; i < this._entitys.length; ++i) {
            this._entitys[i].data.scale(scale, pos);
        }
    }
    rotate(rotate, pos) {
        for (var i = 0; i < this._entitys.length; ++i) {
            this._entitys[i].data.rotate(rotate, pos);
        }
    }
    movePoint(pos) {
        for (var i = 0; i < this._entitys.length; ++i) {
            this._entitys[i].data.move(pos);
        }
    }
    attributeDefs() {
        let result = [];
        this._entitys.forEach(element => {
            if (element instanceof LTText) {
                if (element.subData()._expression === true) {
                    result.push(element.subData());
                }
            }
        });
        return result;
    }
    clone() {
        let ret = new LTBlockReference();
        this._entitys.forEach(sub_ent => {
            ret._entitys.push(sub_ent.clone());
        });
        super.cloneTo(ret);
        return ret;
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        this.startDraw(canvas, main, graphics);
        let polygon = null;
        let polygonBox = null;
        if (info !== undefined && info !== null && info.filter !== undefined && info.filter !== null) {
            if (info.filter.enabled) {
                let pos = info.filter.pos;
                let points = [];
                for (let i = 0; i < pos.length; i++) {
                    points.push(new ltpoint_1.LTPoint(Number(pos[i].x), Number(pos[i].y)));
                }
                polygon = new LTPolyline(points);
                polygonBox = polygon.getBoundingBox();
            }
        }
        for (var i = 0; i < this._entitys.length; ++i) {
            let ent = this._entitys[i];
            if (polygon !== null && polygonBox !== null) {
                //过滤一次
                if (!polygonBox.intersects(ent.getBoundingBox())) {
                    continue;
                }
            }
            ent.worldDraw(canvas, main, { drawblock: true, clip: polygonBox !== null }, graphics);
        }
        this.endDraw(canvas, main);
    }
    /**
     * \brief 设置图层名
     */
    setLayerName(name) {
        this._layername = name;
        for (var i = 0; i < this._entitys.length; ++i) {
            if (this._entitys[i].getLayerName() == "0") {
                this._entitys[i].setLayerName(name);
            }
        }
    }
}
exports.LTBlockReference = LTBlockReference;
/**
 * LTText 文本实体
 */
class LTText extends LTEntity {
    constructor(point) {
        super();
        this._entity_graphics = [];
        if (point instanceof segment_js_1.RText) {
            this._entity_data = point;
        }
        else {
            this._entity_data = new segment_js_1.RText(point, "", 0);
        }
        this.json_value = new Object();
    }
    get [Symbol.toStringTag]() {
        return 'LTText';
    }
    subData() {
        return this._entity_data;
    }
    get fontName() {
        return this.subData().fontName;
    }
    setStrokeWidth(stroke_width) {
        this.subData().height = stroke_width;
    }
    setCenter(center) {
        this.subData().textVertMode = segment_js_1.TextVertMode.kTextVertMid;
        this.subData().textHorzMode = segment_js_1.TextHorzMode.kTextCenter;
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        if (this.subData().height == 0 && this.subData().text == "") {
            return;
        }
        //设置文本信息
        this.startDraw(canvas, main, graphics);
        if (this.json_value != null) {
            canvas.setFont("", false, false, this.subData()._mtext, false);
        }
        canvas.save();
        let pos = this.subData().position;
        if (pos !== null) {
            canvas.translate(pos.x, pos.y);
        }
        canvas.setColor(this.entityColor(canvas.map), this.getLayerName());
        canvas.setRotate(this.subData().getRotate());
        canvas.setStrokeWidth(this.subData().height * this.subData().getScale());
        //判断是否是表达式符合
        let text = this.subData().text;
        if (this.subData()._expression == true && main != false) {
            text = info[text].text;
        }
        let ref_width = this.subData().getRefWidth();
        //替换
        text = text.replace(/%%[cC]/g, "∅");
        text = text.replace(/%%[pP]/g, "±");
        text = text.replace(/%%$/g, "");
        //text = "㎡ ∅111";
        let g = canvas.drawText(this, text, this.subData().textVertMode, this.subData().textHorzMode, this.subData().widthFactor, ref_width);
        //外包测试
        // canvas.setStrokeWidth(0);
        // canvas.drawLineString(this.subData()._bbox_vertices._points);
        canvas.restore();
        this.endDraw(canvas, main);
    }
    clone() {
        let line = new LTText(this.subData().clone());
        super.cloneTo(line);
        line.json_value = this.json_value;
        return line;
    }
    setScale(scale) {
        this.subData().setScale(scale);
    }
    /**
     * \brief 设置绘制点
     */
    setPoint(point) {
        this._entity_data.position = point;
    }
    /**
     * \brief 设置文本内容
     */
    set text(text) {
        this.subData().text = text;
    }
    get text() {
        return this.subData().text;
    }
    /**
     * \brief 设置字体信息
     */
    setFontInfo(info) {
        this.subData().setFontInfo(info);
    }
    /**
     * \brief 设置文本高度
     */
    setHeight(h) {
        this.subData().height = h;
    }
    /**
       * \brief 获取文本内容
       */
    getText() {
        return this.subData().text;
    }
}
exports.LTText = LTText;
/**
 * LTHatch hatch实体
 */
class LTHatch extends LTEntity {
    constructor(points) {
        super();
        if (points instanceof segment_js_1.RHatch) {
            this._entity_data = points;
            return;
        }
        this._entity_data = new segment_js_1.RHatch();
    }
    get [Symbol.toStringTag]() {
        return 'LTHatch';
    }
    subData() {
        return this._entity_data;
    }
    intersectsWith(shape) {
        if (this._entity_data == null) {
            return false;
        }
        return this.subData().intersectsWith(shape.data, true);
    }
    clone() {
        let ret = new LTHatch(this.subData().clone());
        super.cloneTo(ret);
        return ret;
    }
    setPatternScale(sclae) {
        return this.subData().setPatternScale(sclae);
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        this.startDraw(canvas, main, graphics);
        canvas.setColor(this.entityColor(canvas.map));
        if (this.subData().pattern == null &&
            this.subData()._hatchname != null) {
            let current_server = canvas.map.dwgservice;
            if (current_server !== null) {
                let hatch = current_server.getHatch(this.subData()._hatchname);
                this.subData().pattern = hatch;
            }
        }
        this.subData().render(canvas, this, graphics, info);
        this.endDraw(canvas, main);
    }
    /**
     * \brief 设置hatch名字
     */
    set hatchName(name) {
        let current_server = window.cadmap.dwgservice;
        if (current_server === null) {
            return;
        }
        let hatch = current_server.getHatch(name);
        this.subData()._hatchname = name;
        this.subData().pattern = hatch;
    }
    get hatchName() {
        return this.subData()._hatchname;
    }
}
exports.LTHatch = LTHatch;
//标注对象;
class LTDimension extends LTEntity {
    constructor(ext_point1, ext_point2, text, dim_point, text_position, dimtype) {
        super();
        if (ext_point1 instanceof dimension_1.RDimension) {
            this._entity_data = ext_point1;
        }
        else {
            if (ext_point2 instanceof ltpoint_1.LTPoint && typeof text === "string" && dim_point instanceof ltpoint_1.LTPoint) {
                this._entity_data = dimension_1.RDimension.createDimension(ext_point1, ext_point2, text, dim_point, text_position, dimtype);
            }
        }
    }
    get [Symbol.toStringTag]() {
        return 'LTDimension';
    }
    subData() {
        return this._entity_data;
    }
    set height(h) {
        this.subData().height = h;
    }
    setInfo(info) {
        this.subData().setInfo(info);
    }
    setRotate(rotate) {
        this.subData().setRotate(rotate);
    }
    worldDraw(canvas, main, info, graphics) {
        if (!this.visible) {
            return;
        }
        this.startDraw(canvas, main, graphics);
        canvas.setColor(this.entityColor(canvas.map), this.getLayerName());
        this.subData().render(canvas, this, main, graphics);
        this.endDraw(canvas, main);
    }
    clone() {
        let line = new LTDimension(this._entity_data.clone());
        super.cloneTo(line);
        return line;
    }
}
exports.LTDimension = LTDimension;
