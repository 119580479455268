"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RDimStyle = void 0;
const dimension_1 = require("./dimension");
const ltentity_1 = require("./ltentity");
const ltmath_1 = require("./ltmath");
const ltpoint_1 = require("./ltpoint");
const ltutils_1 = require("./ltutils");
const segment_1 = require("./segment");
class RDimStyle {
    constructor() {
        this._values = {};
        this._name = "";
    }
    get name() {
        return this._name;
    }
    set name(n) {
        this._name = n;
    }
    setValue(value) {
        this._values = value;
    }
    getDimXBool(v) {
        let it = this._values[v.toLowerCase()];
        if (it == null) {
            return false;
        }
        return Boolean(it);
        // if (it === "0" && it.toString() === "false")
        // {
        //   return false;
        // }
        // return true;
    }
    getDimXDouble(v) {
        let it = this._values[v.toLowerCase()];
        if (it == null) {
            return 0;
        }
        return Number(it);
    }
    getDimXInt(v) {
        let it = this._values[v.toLowerCase()];
        if (it == null) {
            return 0;
        }
        return Number(it);
    }
    getDimscale() {
        let it = this._values["dimscale"];
        if (it == undefined && it == null) {
            return 1;
        }
        return Number(it);
    }
    getDimString(v) {
        let it = this._values[v.toLowerCase()];
        return it;
    }
    //水平
    static renderDimRotated(canvas, entity, main, dimension, graphics) {
    }
    //线型
    static renderDimAligned(canvas, entity, main, dimension, graphics) {
        //绘制第一条竖线;'
        if (dimension.render_point1 === null || dimension.render_point2 == null || dimension.render_point3 == null || dimension.render_point4 == null) {
            return;
        }
        let points = [];
        points.push(dimension.render_point1);
        points.push(dimension.render_point2);
        dimension.renderLine(dimension.ext1info, points, canvas, entity, graphics);
        //绘制第二条竖线;
        points = [];
        points.push(dimension.render_point3);
        points.push(dimension.render_point4);
        dimension.renderLine(dimension.ext2info, points, canvas, entity, graphics);
        //绘制中间的线；
        points = [];
        points.push(dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0));
        points.push(dimension.render_midpoint2 || new ltpoint_1.LTPoint(0, 0));
        //this.renderLine(this.dimposInfo,points,canvas,entity);
        canvas.drawLineString(points);
        //获取文字内容;
        canvas.save();
        if (dimension._rtext.color != undefined && dimension._rtext.color.toLowerCase() != ltentity_1.dwg_bylayer) {
            canvas.setColor(dimension._rtext.color, entity.getLayerName());
        }
        else {
            canvas.setColor(entity.entityColor(canvas.map), entity.getLayerName());
        }
        //中心点
        canvas.setStrokeWidth(dimension._rtext.height);
        let block_pos = dimension._rtext.position || new ltpoint_1.LTPoint(0, 0);
        var new_text_pos = new ltpoint_1.LTPoint(block_pos.x, block_pos.y);
        canvas.setRotate(dimension._rtext.getRotate());
        canvas.translate(new_text_pos.x, new_text_pos.y);
        canvas.setFont(dimension._rtext.fontName, false, true, false, true);
        dimension.upDateTextWidthFactor();
        canvas.drawText(entity, dimension._rtext.text, segment_1.TextVertMode.kTextVertMid, segment_1.TextHorzMode.kTextCenter, dimension._rtext.widthFactor);
        canvas.restore();
        //中间的箭头
        if (dimension.ext1info.blockname != undefined && dimension.ext1info.blockname != "") {
            let entitypos1 = new ltentity_1.LTEntityPoint(dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0));
            if (entitypos1._drawBlock(entity, dimension.ext1info.blockname, canvas, null, graphics) == true) {
                canvas.save();
                canvas.scale(dimension.blockscale * dimension._scale, dimension.blockscale * dimension._scale);
                let angle = ltutils_1.LTUtils.angle(dimension.render_midpoint2 || new ltpoint_1.LTPoint(0, 0), dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0));
                canvas.drawBlock(entity, dimension.ext1info.blockname, dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0), angle, main, new Object());
                canvas.restore();
            }
        }
        if (dimension.ext2info.blockname != undefined && dimension.ext2info.blockname != "") {
            let entitypos1 = new ltentity_1.LTEntityPoint(dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0));
            if (entitypos1._drawBlock(entity, dimension.ext2info.blockname, canvas, null, graphics) == true) {
                canvas.save();
                canvas.scale(dimension.blockscale * dimension._scale, dimension.blockscale * dimension._scale);
                let angle = ltutils_1.LTUtils.angle(dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0), dimension.render_midpoint2 || new ltpoint_1.LTPoint(0, 0));
                canvas.drawBlock(entity, dimension.ext2info.blockname, dimension.render_midpoint2 || new ltpoint_1.LTPoint(0, 0), angle, main, new Object());
                canvas.restore();
            }
        }
    }
    //角度
    static renderDimAngular(canvas, entity, main, dimension, graphics) {
        let data = dimension;
        if (dimension.render_point1 === null || dimension.render_point2 == null || dimension.render_point3 == null || dimension.render_point4 == null) {
            return;
        }
        //绘制第一条竖线;
        let points = [];
        points.push(dimension.render_point1);
        points.push(dimension.render_point2);
        dimension.renderLine(dimension.ext1info, points, canvas, entity, graphics);
        //绘制第二条竖线;
        points = [];
        points.push(dimension.render_point3);
        points.push(dimension.render_point4);
        dimension.renderLine(dimension.ext2info, points, canvas, entity, graphics);
        //绘制中间的线；
        if (data.rArc != null) {
            canvas.drawArc(data.rArc.getCenter(), data.rArc.getRadius(), data.rArc.getStartAngle(), data.rArc.getEndAngle(), data.rArc.isReversed);
        }
        //获取文字内容;
        canvas.save();
        if (dimension._rtext.color != undefined && dimension._rtext.color.toLowerCase() != ltentity_1.dwg_bylayer) {
            canvas.setColor(dimension._rtext.color, entity.getLayerName());
        }
        else {
            canvas.setColor(entity.entityColor(canvas.map), entity.getLayerName());
        }
        //中心点
        canvas.setStrokeWidth(dimension._rtext.height);
        let block_pos = dimension._rtext.position || new ltpoint_1.LTPoint(0, 0);
        var new_text_pos = new ltpoint_1.LTPoint(block_pos.x, block_pos.y);
        canvas.setRotate(dimension._rtext.getRotate());
        canvas.translate(new_text_pos.x, new_text_pos.y);
        canvas.setFont(dimension._rtext.fontName, false, true, false, true);
        canvas.setRotate(dimension._rtext.getRotate());
        dimension.upDateTextWidthFactor();
        canvas.drawText(entity, dimension._rtext.text, segment_1.TextVertMode.kTextVertMid, segment_1.TextHorzMode.kTextCenter, dimension._rtext.widthFactor);
        canvas.restore();
        //中间的箭头
        if (dimension.ext1info.blockname != undefined && dimension.ext1info.blockname != "") {
            let entitypos1 = new ltentity_1.LTEntityPoint(dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0));
            entitypos1._drawBlock(entity, dimension.ext1info.blockname, canvas, null, graphics);
            canvas.save();
            canvas.scale(dimension.blockscale * dimension._scale, dimension.blockscale * dimension._scale);
            let angle = dimension.ext1info.angle;
            canvas.drawBlock(entity, dimension.ext1info.blockname, dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0), angle, main, new Object());
            canvas.restore();
        }
        if (dimension.ext2info.blockname != undefined && dimension.ext2info.blockname != "") {
            let entitypos1 = new ltentity_1.LTEntityPoint(dimension.render_midpoint1 || new ltpoint_1.LTPoint(0, 0));
            entitypos1._drawBlock(entity, dimension.ext2info.blockname, canvas, null, graphics);
            canvas.save();
            canvas.scale(dimension.blockscale * dimension._scale, dimension.blockscale * dimension._scale);
            let angle = dimension.ext2info.angle;
            canvas.drawBlock(entity, dimension.ext2info.blockname, dimension.render_midpoint2 || new ltpoint_1.LTPoint(0, 0), angle, main, new Object());
            canvas.restore();
        }
    }
    static getDimensionLineShapes(p1, p2, arrow1, arrow2, dimensionData) {
        let dimasz = dimensionData.getDimasz();
        let archTick = dimensionData.useArchTick();
        // arrow angles:
        let arrowAngle1, arrowAngle2;
        // Create dimension line:
        let dimensionLine = new segment_1.RLine(p1, p2);
        if (dimensionData.outsideArrow1 == false) {
            arrowAngle1 = dimensionLine.getDirection2();
        }
        else {
            arrowAngle1 = dimensionLine.getDirection1();
        }
        if (dimensionData.outsideArrow2 == false) {
            arrowAngle2 = dimensionLine.getDirection1();
        }
        else {
            arrowAngle2 = dimensionLine.getDirection2();
        }
        // extend dimension line outside arrows
        let dir = new ltpoint_1.LTPoint(0, 0);
        dir.setPolar(dimensionData.getDimasz() * 2, dimensionLine.getDirection1());
        if (dimensionData.outsideArrow1) {
            if (arrow1) {
                dimensionLine.setStartPoint(p1.minus(dir));
            }
        }
        if (dimensionData.outsideArrow2) {
            if (arrow2) {
                dimensionLine.setEndPoint(p2.plus(dir));
            }
        }
        //ret.append(QSharedPointer<RShape>(new RLine(dimensionLine)));
        let arrow1Pos = ltpoint_1.LTPoint.nullVector();
        if (arrow1) {
            dimensionData.render_midpoint1 = p1;
            dimensionData.ext1info.angle = arrowAngle1;
            //QList<QSharedPointer<RShape> > arrow = this.getArrow(p1, arrowAngle1);
            //ret.append(arrow);
            if (!archTick) {
                arrow1Pos = p1.plus(ltpoint_1.LTPoint.createPolar(dimasz, arrowAngle1 + Math.PI));
            }
        }
        let arrow2Pos = ltpoint_1.LTPoint.nullVector();
        if (arrow2) {
            dimensionData.render_midpoint2 = p2;
            dimensionData.ext2info.angle = arrowAngle2;
            //QList<QSharedPointer<RShape> > arrow = getArrow(p2, arrowAngle2);
            //ret.append(arrow);
            if (!archTick) {
                arrow2Pos = p2.plus(ltpoint_1.LTPoint.createPolar(dimasz, arrowAngle2 + Math.PI));
            }
        }
        dimensionData.updateArrowPos1(arrow1Pos);
        dimensionData.updateArrowPos2(arrow2Pos);
    }
    static adjustExtensionLineFixLength(extLine1, extLine2, addDimExe, dimensionData) {
        // extension lines with fixed length:
        if (dimensionData.isExtLineFix()) {
            let extLineLen = dimensionData.getExtLineFixLength();
            if (ltmath_1.LTMath.fuzzyCompare(extLineLen, 0.0, 1.0e-6)) {
                // value of 0 for extension line fixed length means fixed length is off:
                return;
            }
            if (addDimExe) {
                let dimexe = dimensionData.getDimexe();
                extLineLen += dimexe;
            }
            if (extLine1.isValid()) {
                extLine1.setLength(Math.min(extLine1.getLength(), extLineLen), false);
            }
            if (extLine2.isValid()) {
                extLine2.setLength(Math.min(extLine2.getLength(), extLineLen), false);
            }
        }
    }
    static render(canvas, entity, main, dimension, graphics) {
        switch (dimension.getDimensionType()) {
            case dimension_1.DimensionType.EntityDimRotated:
                this.renderDimAligned(canvas, entity, main, dimension, graphics);
                break;
            case dimension_1.DimensionType.EntityDimAligned:
                this.renderDimAligned(canvas, entity, main, dimension, graphics);
                break;
            // case DimensionType.EntityDimOrdinate:
            //   this.renderDimOrdinate(dimension);
            //     break;
            // case DimensionType.EntityDimRadial:
            //   this.renderDimRadial(dimension);
            //     break;
            // case DimensionType.EntityDimDiametric:
            //   this.renderDimDiametric(dimension);
            //     break;
            case dimension_1.DimensionType.EntityDimAngular2L:
                this.renderDimAngular(canvas, entity, main, dimension, graphics);
                break;
            case dimension_1.DimensionType.EntityDimAngular3P:
                this.renderDimAngular(canvas, entity, main, dimension, graphics);
                break;
            // case DimensionType.EntityDimArcLength:
            //   this.renderDimAngular(dimension);
            //     break;
            default:
                break;
        }
    }
}
exports.RDimStyle = RDimStyle;
