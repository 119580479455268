"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sprinkPreview = exports.drawSprinkler = void 0;
const commandfactory_1 = require("../../cadplugins/map/commandfactory");
const segment_1 = require("../../cadplugins/cad/segment");
const ltfeature_1 = require("../../cadplugins/data/ltfeature");
const sprinklerutils_1 = require("../sprinkler/sprinklerutils");
const ltpoint_1 = require("../../cadplugins/cad/ltpoint");
const selectcommands_1 = require("../../cadplugins/tools/selectcommands");
const ltfields_1 = require("../../cadplugins/data/ltfields");
const sprayMain_1 = require("../sprayMain");
const ltentity_1 = require("../../cadplugins/cad/ltentity");
function* drawSprinkler(map, param_map) {
    //布置区域
    let selectfeatures = [];
    //绘制立管
    let riser_features = [];
    //水流指示器
    let water_features = [];
    //自喷管
    let auto_features = [];
    //主管道
    let mainPipeFeatures = [];
    while (true) {
        //设置出图比例
        let drawscale = 1;
        {
            commandfactory_1.CommandFactory.getInstance().getString(map, "出图比例 1:[100(1)]<1>:");
            let scale_type = yield;
            if (scale_type.done == commandfactory_1.CommandStatus.eDone) {
            }
            if (scale_type.done == commandfactory_1.CommandStatus.eCancel) {
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            if (scale_type.value.toLowerCase() == "1") {
                drawscale = 1;
            }
            else {
                drawscale = Number(scale_type.value) / 100;
            }
        }
        map.select_command.clearSelection();
        {
            let back = false;
            while (true) {
                commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, { command_tip: "请选择布置区域", select_type: selectcommands_1.SelectType.eSelectBox, contain_select: true, back: true });
                let select_type = yield;
                if (select_type.done == commandfactory_1.CommandStatus.eDone) {
                    break;
                }
                if (select_type.done == commandfactory_1.CommandStatus.eCancel) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                if (select_type.done == commandfactory_1.CommandStatus.eKeyWord && select_type.value == 2) {
                    back = true;
                    break;
                }
                selectfeatures = select_type.value;
                if (selectfeatures === null || selectfeatures.length == 0) {
                    continue;
                }
                break;
            }
            map.select_command.clearSelection();
            //用map.select 显示选择
            map.select_command.addSelectFeatures(selectfeatures);
            map.select_command.show();
            if (back == true) {
                continue;
            }
        }
        let last_postion = ltpoint_1.LTPoint.nullVector();
        let riserName = ""; //自喷立管的名字;
        while (true) {
            if (selectfeatures.length > 0 && mainPipeFeatures.length > 0 && mainPipeFeatures.length > 0 && mainPipeFeatures.length > 0
                && mainPipeFeatures.length > 0) {
                commandfactory_1.CommandFactory.getInstance().getString(map, "喷淋绘制[自喷立管(1) 自喷管(2) 水流指示器(3) 主管道(4) 喷淋布置(5) 返回上一步(6)]<5>:");
            }
            else {
                commandfactory_1.CommandFactory.getInstance().getString(map, "喷淋绘制[自喷立管(1) 自喷管(2) 水流指示器(3) 主管道(4) 返回上一步(6)]<1>:");
            }
            let select_mode = yield;
            if (select_mode.done == commandfactory_1.CommandStatus.eCancel) {
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            if (select_mode.value.toLowerCase() == "1") {
                while (true) {
                    commandfactory_1.CommandFactory.getInstance().getString(map, "请输入自喷立管名:");
                    let risernameyield = yield;
                    if (risernameyield.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    //没名则继续
                    if (risernameyield.value == "") {
                        continue;
                    }
                    riserName = risernameyield.value;
                    break;
                }
                commandfactory_1.CommandFactory.getInstance().getString(map, "请选择自喷立管[选择(1) 绘制(2) 返回上一步(3)]<2>:");
                let select_riser_type = yield;
                //清空上一次选择;
                map.select_command.clearSelection();
                //绘制完成之后绘制自喷管;
                if (select_riser_type.done == commandfactory_1.CommandStatus.eCancel) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                if (select_riser_type.value.toLowerCase() == "1") {
                    function queryStardPipeCallback(fea) {
                        let data = fea.getGeometry().data;
                        if (data instanceof segment_1.RBlockReference) {
                            if (data.blockName === sprinklerutils_1.STANDPIPE_NAME) {
                                return true;
                            }
                        }
                        return false;
                    }
                    //选择时要清除上一次的结果;
                    last_postion = ltpoint_1.LTPoint.nullVector();
                    let param = { command_tip: "请选择自喷立管:", select_type: selectcommands_1.SelectType.eSelectPointAndBox, callback: queryStardPipeCallback };
                    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, param);
                    let mouse1 = yield;
                    if (mouse1.done == commandfactory_1.CommandStatus.eDone) {
                        break;
                    }
                    if (mouse1.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let fea = mouse1.value[0];
                    fea._xdata.riser_name = riserName;
                    riser_features.push(fea);
                }
                else if (select_riser_type.value.toLowerCase() == "2") {
                    //绘制自喷立管;
                    let param = { blockName: sprinklerutils_1.STANDPIPE_NAME, layername: sprinklerutils_1.STANDPIPE_LAYER, onlyone: true, prompt_angle: false, noSaveFeature: true };
                    commandfactory_1.CommandFactory.getInstance().execYieldCommand("insert", map, param);
                    let draw_block = yield;
                    if (draw_block.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let entity = draw_block.value[0];
                    let feature = new ltfeature_1.LTFeature({
                        geometry: entity,
                        layer: sprinklerutils_1.STANDPIPE_LAYER
                    });
                    last_postion = entity.data.position;
                    feature.setValue(ltfields_1.FieldNames.EntityLayer, sprinklerutils_1.STANDPIPE_LAYER);
                    feature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
                    feature.setValue(ltfields_1.FieldNames.Width, 0.0);
                    feature.setValue(ltfields_1.FieldNames.Rotate, entity.subData().getRotate());
                    feature._xdata.riser_name = riserName;
                    feature._xdata.source = "autogenerate";
                    map.addFeature(feature);
                    riser_features.push(feature);
                    //标注
                    //原点确定
                    let points = [];
                    points.push(entity.data.position);
                    let lineEntity = new ltentity_1.LTPolyline(points);
                    let pparam = { tip: "绘制自喷管标注 ", layername: sprinklerutils_1.STANDPIPE_LAYER, noSaveFeature: true, lineEntity: lineEntity };
                    commandfactory_1.CommandFactory.getInstance().execYieldCommand("line", map, pparam);
                    let result = yield;
                    if (result.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let atlayer = map.getLayerByName(sprinklerutils_1.STANDPIPE_LAYER);
                    if (atlayer === null || result.value === null) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let mainFeature = new ltfeature_1.LTFeature({
                        geometry: result.value.clone(),
                        layer: atlayer
                    });
                    mainFeature.setValue(ltfields_1.FieldNames.EntityLayer, atlayer.name);
                    mainFeature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
                    mainFeature.setValue(ltfields_1.FieldNames.Width, 0.0);
                    mainFeature.setValue(ltfields_1.FieldNames.Rotate, 0.0);
                    mainFeature._xdata.source = "autogenerate";
                    map.addFeature(mainFeature);
                    //文本
                    let ptext = { tip: "绘制自喷管标注 ", color: "0xff00ff", fontname: "sprinkler_200", widthFactor: 0.6, content: riserName, fontSize: 300 * drawscale, angle: 0, continue: false, noSaveFeature: true };
                    commandfactory_1.CommandFactory.getInstance().execYieldCommand("drawText", map, ptext);
                    let res_txt = yield;
                    if (res_txt.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let txtFeature = new ltfeature_1.LTFeature({
                        geometry: res_txt.value.clone(),
                        layer: atlayer
                    });
                    txtFeature.setValue(ltfields_1.FieldNames.EntityLayer, sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
                    txtFeature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
                    txtFeature.setValue(ltfields_1.FieldNames.Width, 300 * drawscale);
                    txtFeature.setValue(ltfields_1.FieldNames.Rotate, 0.0);
                    txtFeature._xdata.source = "autogenerate";
                    map.addFeature(txtFeature);
                }
            }
            else if (select_mode.value.toLowerCase() == "3") {
                commandfactory_1.CommandFactory.getInstance().getString(map, "请选择水流指示器[选择(1) 绘制(2) 返回上一步(3)]<1>:");
                let select_riser_type = yield;
                //清空上一次选择;
                map.select_command.clearSelection();
                //绘制完成之后绘制自喷管;
                if (select_riser_type.done == commandfactory_1.CommandStatus.eCancel) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                if (select_riser_type.value.toLowerCase() == "1") {
                    function queryWaterFlowCallback(fea) {
                        let data = fea.getGeometry().data;
                        if (data instanceof segment_1.RBlockReference) {
                            if (data.blockName === sprinklerutils_1.WATERFLOW_NAME) {
                                return true;
                            }
                        }
                        return false;
                    }
                    let param = { command_tip: "请选择一个水流指示器:", select_type: selectcommands_1.SelectType.eSelectPointAndBox, callback: queryWaterFlowCallback };
                    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, param);
                    let mouse1 = yield;
                    if (mouse1.done == commandfactory_1.CommandStatus.eDone) {
                        break;
                    }
                    if (mouse1.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    mouse1.value[0]._xdata.riser_name = riserName;
                    mouse1.value[0]._xdata.source = "autogenerate";
                    water_features.push(mouse1.value[0]);
                }
                else if (select_riser_type.value.toLowerCase() == "2") {
                    //绘制水流指示器;
                    let command_name = "insert";
                    if (last_postion.valid) {
                        command_name = JSON.stringify({ commandName: "insert", paramList: [`${last_postion.x},${last_postion.y}`] });
                    }
                    let param = { blockName: sprinklerutils_1.WATERFLOW_NAME, layername: sprinklerutils_1.WATERFLOW_LAYER, onlyone: true, prompt_angle: true };
                    commandfactory_1.CommandFactory.getInstance().execYieldCommand(command_name, map, param);
                    let draw_block = yield;
                    if (draw_block.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    draw_block.value[0]._xdata.riser_name = riserName;
                    draw_block.value[0]._xdata.source = "autogenerate";
                    water_features.push(draw_block.value[0]);
                }
            }
            else if (select_mode.value.toLowerCase() == "2") {
                //绘制自喷管
                {
                    let param = { tip: "绘制自喷管 ", layername: sprinklerutils_1.STANDPIPE_LAYER, noSaveFeature: true };
                    let command_name = "pline";
                    if (last_postion.valid) {
                        command_name = JSON.stringify({ commandName: "pline", paramList: [`${last_postion.x},${last_postion.y}`] });
                    }
                    commandfactory_1.CommandFactory.getInstance().execYieldCommand(command_name, map, param);
                    let result = yield;
                    if (result.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let atlayer = map.getLayerByName(sprinklerutils_1.STANDPIPE_LAYER);
                    if (atlayer === null || result.value === null) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let mainFeature = new ltfeature_1.LTFeature({
                        geometry: result.value.clone(),
                        layer: atlayer
                    });
                    let polyline = result.value;
                    last_postion = polyline.subData().vertices[polyline.subData().vertices.length - 1];
                    mainFeature.setValue(ltfields_1.FieldNames.EntityLayer, atlayer.name);
                    mainFeature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
                    mainFeature.setValue(ltfields_1.FieldNames.Width, 0.0);
                    mainFeature.setValue(ltfields_1.FieldNames.Rotate, 0.0);
                    mainFeature._xdata.riser_name = riserName;
                    mainFeature._xdata.source = "autogenerate";
                    map.addFeature(mainFeature);
                    auto_features.push(mainFeature);
                }
            }
            else if (select_mode.value.toLowerCase() == "4") {
                //绘制主管道;
                {
                    let param = { tip: "绘制主管道 ", layername: sprinklerutils_1.MAINPIPE_LAYER, noSaveFeature: true };
                    commandfactory_1.CommandFactory.getInstance().execYieldCommand("pline", map, param);
                    let result = yield;
                    if (result.done == commandfactory_1.CommandStatus.eCancel) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let atlayer = map.getLayerByName(sprinklerutils_1.MAINPIPE_LAYER);
                    if (atlayer === null || result.value === null) {
                        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                    }
                    let mainFeature = new ltfeature_1.LTFeature({
                        geometry: result.value.clone(),
                        layer: atlayer
                    });
                    mainFeature.setValue(ltfields_1.FieldNames.EntityLayer, atlayer.name);
                    //mainFeature.setValue(FieldNames.EntityType, "AcDbBlockReference");
                    mainFeature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
                    mainFeature.setValue(ltfields_1.FieldNames.Width, 0.0);
                    mainFeature.setValue(ltfields_1.FieldNames.Rotate, 0.0);
                    mainFeature._xdata.riser_name = riserName;
                    mainFeature._xdata.source = "autogenerate";
                    map.addFeature(mainFeature);
                    mainPipeFeatures.push(mainFeature);
                }
            }
            else if (select_mode.value.toLowerCase() == "5") {
                if (selectfeatures.length > 0 && riser_features.length > 0 && water_features.length > 0 && auto_features.length > 0
                    && mainPipeFeatures.length > 0) {
                    function simpleGenerator() {
                        return __awaiter(this, void 0, void 0, function* () {
                            yield new Promise(resolve => setTimeout(resolve, 3));
                            map.printMessage("正在布置管线，请稍等!");
                            yield new Promise(resolve => setTimeout(resolve, 3));
                            let main = new sprayMain_1.SprayMain(map.canvas, map.getLayers(), map);
                            param_map.select = selectfeatures;
                            param_map.riser = riser_features;
                            param_map.waterflow = water_features;
                            param_map.auto = auto_features;
                            param_map.mainpipe = mainPipeFeatures;
                            param_map.drawscale = drawscale;
                            param_map.riserName = riserName;
                            //太久阻塞会主线程
                            try {
                                main.execute(param_map);
                            }
                            catch (err) {
                                map.printMessage("生成管线失败");
                                console.error(err);
                            }
                        });
                    }
                    simpleGenerator();
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
            }
            else if (select_mode.value.toLowerCase() == "6") {
                break;
            }
        }
    }
    return { value: {}, done: commandfactory_1.CommandStatus.eDone };
}
exports.drawSprinkler = drawSprinkler;
function* sprinkPreview(map, param_map) {
    const callback = (layers) => {
        let select_command = window.cadmap.select_command;
        select_command.clear();
        for (let layer_name of layers) {
            let layer_obj = map.getLayerByName(layer_name);
            if (layer_obj) {
                let glow_filter = new window.PIXI.GlowFilter({ color: 0xff0000 });
                layer_obj.layerGraphics.filters = [glow_filter];
            }
        }
    };
    const cancel_callback = (layers) => {
        let select_command = window.cadmap.select_command;
        select_command.clear();
        for (let layer_name of layers) {
            let layer_obj = map.getLayerByName(layer_name);
            if (layer_obj) {
                layer_obj.layerGraphics.filters = [];
            }
        }
    };
    let layers = param_map.layers;
    if (layers == undefined) {
        layers = [];
    }
    if (layers.length !== 0) {
        callback(layers);
    }
    let clone_layers = layers.slice();
    while (true) {
        commandfactory_1.CommandFactory.getInstance().getPoint(map, "点选数据获取图层加入:");
        let mouse1 = yield;
        if (mouse1.done == commandfactory_1.CommandStatus.eDone) {
            cancel_callback(layers);
            return { value: layers, done: commandfactory_1.CommandStatus.eDone };
        }
        else if (mouse1.done == commandfactory_1.CommandStatus.eCancel) {
            cancel_callback(layers);
            return { value: clone_layers, done: commandfactory_1.CommandStatus.eDone };
        }
        let mouse_pos = mouse1.value;
        let feature = map.selectSingleFeature(mouse_pos);
        if (feature == null) {
            continue;
        }
        if (layers.indexOf(feature.layer.name) == -1) {
            feature.layer.layerGraphics.filters = [new window.PIXI.GlowFilter({ color: 0xff0000 })];
            layers.push(feature.layer.name);
        }
        else {
            //再次点选到相同的就取消;
            let index = layers.indexOf(feature.layer.name);
            layers = layers.splice(index, 1);
            let layer_obj = map.getLayerByName(feature.layer.name);
            if (layer_obj) {
                layer_obj.layerGraphics.filters = [];
            }
        }
    }
    return { value: layers, done: commandfactory_1.CommandStatus.eDone };
}
exports.sprinkPreview = sprinkPreview;
